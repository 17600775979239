import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import buttonStyle from './style/buttonStyle';

function RegularButton(props) {
  const {
    classes,
    color,
    round,
    children,
    fullWidth,
    disabled,
    customClass,
    right,
    btnMessages,
    justIcon,
    size,
    wd,
    noBorder,
    resetPadding,
    ...rest
  } = props;

  const btnClasses = cx({
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [customClass]: customClass,
    [classes.right]: right,
    [classes.justIcon]: justIcon,
    [classes.wd]: wd,
    [classes[size]]: size,
    [classes.btnMessages]: btnMessages,
    [classes.noBorder]: noBorder,
    [classes.resetPadding]: resetPadding,
  });
  return (
    <Button {...rest} className={`${classes.button} ${btnClasses}`} disabled={props.disabled}>
      {children}
    </Button>
  );
}

RegularButton.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'orange',
    'lightGray',
    'defaultNoBackground',
    'primaryNoBackground',
    'infoNoBackground',
    'successNoBackground',
    'warningNoBackground',
    'dangerNoBackground',
    'defaultBordered',
    'primaryBordered',
    'white',
    'facebook',
    'google',
  ]),
  resetPadding: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  customClass: PropTypes.string,
  onClick: PropTypes.func,
  // make the button's min width to 160px
  wd: PropTypes.bool,
  // make the button smaller
  justIcon: PropTypes.bool,
  // button will float right
  right: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'xs']),
};

export default withStyles(buttonStyle)(RegularButton);

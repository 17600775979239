import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// material-ui components
import { AppBar, Hidden, IconButton, Toolbar } from '@material-ui/core';

// material-ui icons
import { Menu, ViewList } from '@material-ui/icons';

// core components
import CustomIconButton from 'components/CustomButtons/IconButton';

// style
import headerStyle from 'components/Header/style/headerStyle';
import withStyles from '@material-ui/core/styles/withStyles';

const Header = ({ ...props }) => {
  const { classes, color, rtlActive } = props;
  const appBarClasses = cx({
    [` ${classes[color]}`]: color,
  });
  const sidebarMinimize = `${classes.sidebarMinimize} ${cx({
    [classes.sidebarMinimizeRTL]: rtlActive,
  })}`;

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar disableGutters className={classes.container}>
        <Hidden smDown>
          <div className={sidebarMinimize}>
            {props.miniActive && (
              <CustomIconButton color='white' onClick={props.sidebarMinimize}>
                <ViewList className={classes.sidebarMiniIcon} />
              </CustomIconButton>
            )}
          </div>
        </Hidden>
        <Hidden mdUp>
          {props.newHeader ? (
            <div className={classes.responsiveMenu}>
              <Menu onClick={props.handleDrawerToggle} className={classes.burgerMenu} />
              <img
                src={`${__ENV__.PUBLIC_URL}/logo-mobile-header.svg`}
                alt='logo'
                style={{
                  margin: 'auto',
                  height: '55px',
                }}
              />
            </div>
          ) : (
            <IconButton
              className={classes.appResponsive}
              color='inherit'
              aria-label='open drawer'
              onClick={props.handleDrawerToggle}>
              <Menu />
            </IconButton>
          )}
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
};

export default withStyles(headerStyle)(Header);

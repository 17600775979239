import React, { Component, Fragment } from 'react';
import querystring from 'querystring';
import axios from 'axios';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import cx from 'classnames';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import searchBarStyle from './style/searchBarStyle';
import CustomInput from '../CustomInput/CustomInput';

import { poweredByGoogle } from '../../data';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      department: 0,
      errorMessage: '',
      isGeocoding: false,
    };
  }

  handleChange = (address) => {
    if (!this.props.disabled) {
      this.setState({
        address,
        errorMessage: '',
      });
      this.props.handleChange();
    }
  };

  handleSelect = (selected) => {
    this.setState({ isGeocoding: true });
    geocodeByAddress(selected)
      .then((res) => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        this.latLngToDepartment(lat, lng).then((department) => {
          this.setState({
            address: selected,
            department: department,
            isGeocoding: false,
          });
          this.props.handleSelect(selected, lat, lng, department);
        });
      })
      .catch((error) => {
        this.setState({ isGeocoding: false });
        console.log('error', error); // eslint-disable-line no-console
      });
  };

  handleCloseClick = () => {
    this.setState({ address: '', errorMessage: '' });
    this.props.handleChange();
  };

  handleError = (status, clearSuggestions) => {
    console.log('Error from Google Maps API', status); // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };

  latLngToDepartment = (lat, lon) => {
    return axios
      .get(
        `https://geo.api.gouv.fr/communes?${querystring.stringify({
          fields: 'codeDepartement',
          format: 'json',
          geometry: 'centre',
          lat,
          lon,
        })}`,
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data[0]) {
            if (Object.prototype.hasOwnProperty.call(res.data[0], 'codeDepartement')) {
              return res.data[0].codeDepartement;
            }
          }
        }
        return 0;
      });
  };

  render() {
    const { address, errorMessage } = this.state;
    const { classes, className, noLabel, id, error, success, type, labelText, value, multipleCountry, helpText } =
      this.props;
    let country = ['fr'];
    if (multipleCountry) country = ['fr', 'ch', 're', 'gy', 'yt'];
    const searchOptionsTypes = type ? type : 'geocode';
    const valueInput = value && address.length === 0 ? value : address;

    return (
      <div className={classes.container}>
        <PlacesAutocomplete
          onChange={this.handleChange}
          value={valueInput}
          onSelect={this.handleSelect}
          onError={this.handleError}
          shouldFetchSuggestions={address.length > 2}
          searchOptions={{
            types: [searchOptionsTypes],
            componentRestrictions: { country },
          }}
          highlightFirstSuggestion={true}>
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            const { value, ...inputProps } = getInputProps();
            return (
              <Fragment>
                <div className={classes.searchInputContainer}>
                  <CustomInput
                    success={success}
                    error={error}
                    labelText={labelText}
                    helpText={helpText}
                    formControlProps={{
                      fullWidth: true,
                      ...(className && { className }),
                    }}
                    id={id}
                    value={value}
                    inputProps={inputProps}
                  />

                  {address.length > 0 && (
                    <button
                      className={cx(
                        classes.clearButton,
                        noLabel ? classes.buttonWithoutLabel : classes.buttonWithLabel,
                      )}
                      onClick={this.handleCloseClick}>
                      effacer
                    </button>
                  )}
                </div>
                {suggestions.length > 0 && (
                  <div className={classes.autocompleteContainer}>
                    {suggestions.map((suggestion) => {
                      // const className = classnames('Demo__suggestion-item', {
                      //   'Demo__suggestion-item--active': suggestion.active
                      // })
                      const suggestionItem = cx({
                        [classes.suggestionItem]: true,
                        [classes.suggestionItemActive]: suggestion.active,
                      });
                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className: suggestionItem,
                          })}>
                          <strong>{suggestion.formattedSuggestion.mainText}</strong>{' '}
                          <small>{suggestion.formattedSuggestion.secondaryText}</small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                    <div className={classes.dropdownFooter}>
                      <div>
                        <img alt='Powered by Google' src={poweredByGoogle} className={classes.dropdownFooterImage} />
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            );
          }}
        </PlacesAutocomplete>
        {errorMessage.length > 0 && <div className={classes.errorMmessage}>Oups ... Pas de résultat</div>}
      </div>
    );
  }
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  noLabel: PropTypes.bool,
  id: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  type: PropTypes.string,
  labelText: PropTypes.node,
  value: PropTypes.string,
  multipleCountry: PropTypes.bool,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.bool]),
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
};

SearchBar.defaultProps = {
  disabled: false,
};

export default withStyles(searchBarStyle)(SearchBar);

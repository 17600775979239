import React, { Fragment, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

// material-ui components
import {
  AppBar,
  FormControlLabel,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core';

import GridContainer from 'components/Grid/GridContainer';
import ItemGrid from 'components/Grid/ItemGrid';
import SweetModal from 'components/SweetModal/SweetModal';
import Button from 'components/CustomButtons/Button';

import pagesHeaderStyle from 'components/Header/style/pagesHeaderStyle';
import withStyles from '@material-ui/core/styles/withStyles';

import { Menu, ContactSupport, Dashboard } from '@material-ui/icons';

import { routes } from '../../routes/menu/auth';

const PagesHeader = ({ classes, color }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isOpenNeedHelpModal, setIsOpenNeedHelpModal] = useState(false);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => location.pathname.indexOf(routeName) > -1;

  const toggleNeedHelpModal = () => {
    setIsOpenNeedHelpModal(!isOpenNeedHelpModal);
  };

  const handleDrawerToggle = () => window.innerWidth < 960 && setOpen(!open);

  const appBarClasses = cx({
    [` ${classes[color]}`]: color,
  });

  const modal = (
    <SweetModal
      key='needHelpModal'
      title="Vous avez besoin d'aide ?"
      noCancel
      noSubmit
      noClose
      isOpen={isOpenNeedHelpModal}
      inputs={[
        <p key='needHelpContent'>
          Un problème pour vous connecter, contactez-nous à&nbsp;
          <a href='mailto:contact@viensvoirmontaf.fr'>contact@viensvoirmontaf.fr</a>
        </p>,
      ]}
      toggleModal={toggleNeedHelpModal}
      customFooter={
        <Button onClick={toggleNeedHelpModal} color={'primary'}>
          Fermer
        </Button>
      }
    />
  );

  const list = (listClass) => (
    <List className={listClass}>
      <ListItem className={classes.listItem}>
        <a href={'https://www.viensvoirmontaf.fr'} className={classes.navLink}>
          <ListItemIcon className={classes.listItemIcon}>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary={'Accueil'} disableTypography={true} className={classes.listItemText} />
        </a>
      </ListItem>
      {routes.map((prop, key) => {
        if (prop.pathTo) {
          return null;
        }
        const navLink =
          classes.navLink +
          cx({
            [` ${classes.navLinkActive}`]: activeRoute(prop.path),
          });
        return (
          <ListItem key={key} className={classes.listItem}>
            <NavLink to={prop.path} className={navLink} onClick={handleDrawerToggle}>
              <ListItemIcon className={classes.listItemIcon}>
                <prop.icon />
              </ListItemIcon>
              <ListItemText primary={prop.short} disableTypography={true} className={classes.listItemText} />
            </NavLink>
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <Fragment>
      {modal}
      <AppBar elevation={0} position='static' className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container} disableGutters>
          <GridContainer alignItems='center' className={classes.logoWrapper}>
            <ItemGrid noPadding>
              <FormControlLabel
                className={classes.burgerMenu}
                control={<Menu />}
                onClick={handleDrawerToggle}
                label='MENU'
              />
            </ItemGrid>
            <ItemGrid noPadding>
              <img src={'/logo-main-header.svg'} alt='logo' className={classes.logo} />
            </ItemGrid>
            <ItemGrid>{list(classes.list)}</ItemGrid>
          </GridContainer>
          <div
            className={classes.needHelp}
            onClick={toggleNeedHelpModal}
            onKeyPress={toggleNeedHelpModal}
            role='button'
            tabIndex={0}>
            <span className='text'>Vous avez besoin d&apos;aide ?</span>
            <span className='icon'>
              <ContactSupport />
            </span>
          </div>
          <SwipeableDrawer
            variant='temporary'
            anchor='left'
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            onOpen={() => {}}>
            {list(classes.listMobile)}
          </SwipeableDrawer>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['whiteColor', 'blackColor', 'primary', 'info', 'success', 'warning', 'danger']),
};

export default withStyles(pagesHeaderStyle)(PagesHeader);

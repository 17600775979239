import React, { Component } from 'react';
import axios from 'axios';
import { errorServer, errorClient } from '../data';
import { hasKey } from '../helpers/helpers';

const Requests = (WrappedComponent) => {
  return class NewComponent extends Component {
    state = {
      data: [],
      messages: [],
      offers: [],
      noMoreData: false,
      //noMoreFavs: false,
      element: {},
      selectedElement: null,
      loading: true,
      form: false,
      favsCount: null,
      needFavs: true,
    };

    fetch = ({ url, limit, page, query, fetchMore = false }) => {
      return new Promise((resolve) => {
        axios
          .get(url, { params: { query, limit, page } })
          .then((res) => {
            if (res.status === 201) {
              this.setState(
                (currentState) => ({
                  loading: false,
                  data: hasKey(res.data, 'more') ? [...currentState.data, ...res.data.data] : res.data,

                  ///////////////////////////////////////
                  messages: hasKey(res.data, 'messages')
                    ? fetchMore
                      ? [...currentState.messages, ...res.data.messages]
                      : res.data.messages
                    : [...currentState.messages],

                  message: hasKey(res.data, 'message') ? res.data.message : '',
                  isSnackbar: hasKey(res.data, 'message') && true,
                  status: hasKey(res.data, 'message') ? 'warning' : '',
                  noMoreData: hasKey(res.data, 'noMoreData') && true,
                }),
                () => {
                  resolve(res.data);
                },
              );
            }
          })
          .catch((error) => this.catchError(error));
      });
    };

    fetchOne = (url) => {
      return new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            if (res.status === 201) {
              this.setState(
                {
                  loading: false,
                  element: { ...res.data },
                },
                () => {
                  resolve(res.data);
                },
              );
            }
          })
          .catch((error) => this.catchError(error));
      });
    };

    send = async ({ method, url, data = {}, snack = true }) => {
      return axios({
        method,
        url,
        data: {
          ...data,
        },
      })
        .then((res) => {
          if (res.status === 201) {
            // parfois on veut envoyer de la donnée sans retourner une snackbar
            if (snack) {
              this.props.context.handleSnackbar({
                message: hasKey(res.data, 'message') ? res.data.message : '',
                status: hasKey(res.data, 'status') ? res.data.status : 'success',
              });

              //si c'est user on update Context en mode classique
              if (hasKey(res.data, 'user')) {
                this.props.context.updateUser(res.data.user);
              }
              //par contre si on reçoit un 'doc', on ne sait pas ce qu'on doit updater dans context
              //ducoup on le return là où il faut et updatera là-bas
              // (voir Pro/Profil - ligne 287)
              if (hasKey(res.data, 'doc')) {
                return res.data.doc;
              }
            }
            return res;
          }
        })
        .catch((error) => this.catchError(error));
    };

    handleResponse = ({ data, form, status, message }) => {
      this.setState(() => ({
        data: data !== undefined ? data : [...this.state.data], // TODO: j'aime pas, on update que ce que ce qui ce doit se update
        form,
      }));
      if (status && message) {
        this.props.context.handleSnackbar({
          status,
          message,
        });
      }
    };

    catchError = (error) => {
      this.setState({ loading: false });
      this.props.context.handleSnackbar({
        status: 'danger',
        message: this.handleErrorMessage(error),
      });
    };

    handleErrorMessage = (error) => {
      return error.response
        ? error.response.data && error.response.data.error
          ? error.response.data.error
          : errorServer
        : errorClient;
    };

    toggleModal = (action) => this.setState({ form: !this.state.form, action });

    modalForm = () => this.setState({ form: true });

    render() {
      const {
        data,
        messages,
        offers,
        message,
        element,
        loading,
        selectedElement,
        form,
        action,
        favsCount,
        needFavs,
        noMoreData,
      } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          key='Requests'
          // object - data
          data={data}
          messages={messages}
          offers={offers}
          message={message}
          needFavs={needFavs}
          noMoreData={noMoreData}
          //noMoreFavs={noMoreFavs}
          favsCount={favsCount}
          element={element}
          selectedElement={selectedElement}
          //request
          fetch={this.fetch}
          fetchOne={this.fetchOne} // referent/messages + student/offers
          send={this.send}
          //small request
          toggleModal={this.toggleModal}
          action={action}
          //boolean
          form={form}
          loading={loading}
          //handle response
          handleResponse={this.handleResponse}
          handleErrorMessage={this.handleErrorMessage}
        />
      );
    }
  };
};

export default Requests;

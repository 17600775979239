import Jeunes from 'views/Association/Jeunes.js';

import Profil from 'views/Association/ProfilPage';

import Disconnection from 'views/Common/DisconnectionPage';
import StudentApplications from 'components/StudentApplications/StudentApplicationsPage.tsx';
import InfoAsso from 'views/Association/InfoAsso.tsx';
import Router from './routesHOC';

export const routes = [
  {
    path: '/modifier-profil',
    component: Profil,
  },
  {
    path: '/deconnexion',
    component: Disconnection,
  },
  {
    path: '/jeunes/:id/candidatures',
    component: StudentApplications,
  },
  {
    path: '/info-asso',
    component: InfoAsso,
  },
  {
    path: '/jeunes',
    component: Jeunes,
  },
  {
    path: '',
    pathTo: '/jeunes',
  },
];

export default Router(routes);

import { primaryColor } from '../../../assets/styles/jss/main';

const loaderStyle = () => ({
  containerWrapper: {
    height: 'calc(100vh - 212px)',
  },
  root: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  colorPrimary: {
    backgroundColor: 'rgba(91,196,186, 0.2)',
  },
  barColorPrimary: {
    backgroundColor: primaryColor,
  },
  circlePrimary: {
    color: '#12ccc0',
  },
});

export default loaderStyle;

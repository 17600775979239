export const errorServer = 'Problème avec le serveur, réessayez plus tard !';
export const errorClient = 'Un problème est survenu, réessayez plus tard !';

export const PRO_IMAGE_RIGHTS_CHECKBOX = `J'accepte de céder mon droit à l'image et autorise l'utilisation des photographies et/ou vidéos prises de moi dans le cadre d'action ViensVoirMonTaf.`;

export const SECTORS = [
  'Alimentation & Tourisme',
  'Arts & Culture',
  'Commerce & Vente',
  'Construction',
  'Droit & Politique',
  'Éducation & Jeunesse',
  'Environnement & Animaux',
  'Journalisme & Communication',
  'Organisation & Administratif',
  'Santé & Solidarité',
  'Sciences & techniques',
  'Sport',
];

// Be careful, updating this value will require db migration
export const OTHER_JOB_ENTRY = 'Autre';
export const OTHER_JOB_PREFFIX = 'Autre : ';

export const JOBS = [
  'Principal·e',
  'Principal.e Adjoint.e',
  'CPE',
  'Prof Principal·e de 3e',
  'Coordinateur·rice REP',
  'Secrétaire',
  OTHER_JOB_ENTRY,
];

export const JUSTIFICATIONS = [
  {
    name: 'J’ai déjà un retenu un autre candidat',
    _id: 'Le professionnel a déjà retenu un autre candidat pour ce stage et il ne peut pas en prendre d’autres.',
  },
  {
    name: 'Je ne suis pas disponible à cette période',
    _id: "Le professionnel n'est pas disponible la semaine de ton stage.",
  },
  {
    name: 'La candidature ne correspond pas au stage',
    _id: `Le professionnel a estimé que la candidature ne correspondait pas bien au profil attendu pour ce stage.
Le stage ne correspond pas à ce que tu cherches, ou ta lettre de motivation n'était pas assez claire ou détaillée.`,
  },
];

export const JUSTIFICATIONSAFTERHIRING = [
  {
    name: "L'élève ne s'est pas manifesté",
    _id: 'Elève non manifesté',
  },
  {
    name: "L'élève a annulé son stage",
    _id: 'Annulation Elève',
  },
  {
    name: 'Je ne suis plus disponible',
    _id: `Non disponible`,
  },
  {
    name: 'Autre',
    _id: `Autres`,
  },
];

export const mainBackground = 'https://vvmt.ams3.digitaloceanspaces.com/static/register.jpeg';

export const avatarPlaceholder = 'https://vvmt.ams3.digitaloceanspaces.com/static/avatar-placeholder.jpg';

export const poweredByGoogle = 'https://vvmt.ams3.digitaloceanspaces.com/static/powered-by-google.png';

export const tutoStudentDiary = 'https://vvmt.ams3.digitaloceanspaces.com/static/tuto-carnet.jpg';

export const tutorGuide = 'https://vvmt.ams3.digitaloceanspaces.com/static/guide-tuteurs-tutrices.pdf';

export const fbTextPro = (title, company) => {
  return `Je propose un stage de 3ème sur ViensVoirMonTaf :
        ${title} chez ${company}
        Vous aussi, ouvrez votre porte aux jeunes sans réseau de l’éducation prioritaire et publiez une offre de stage, ça peut être LE coup de pouce qui fera basculer un parcours !`;
};

export const twitterTextPro = (company) => {
  return `Je propose un stage de 3ème chez ${company} sur ViensVoirMonTaf aux collégiens de l'éducation prioritaire.
Vous aussi, ouvrez votre porte aux jeunes sans réseau et publiez une offre de stage, ça peut être LE coup de pouce qui fera basculer un parcours !`;
};

export const shareTextStudent = (title, company) => {
  return `J'ai trouvé une offre de stage de ${title} chez ${company} sur www.viensvoirmontaf.fr.
@Viensvoirmontaf est un site pour trouver son stage de 3eme, allez voir ça vaut le coup !`;
};

export const stepQuizzList = [
  { id: 'avant', name: 'Avant' },
  { id: 'pendant', name: 'Pendant' },
  { id: 'rapport', name: 'Rapport de stage' },
  { id: 'apres', name: 'Apres' },
];

export const stepChecklistList = [
  { id: 'avant', name: 'Avant' },
  { id: 'pendant', name: 'Pendant' },
  { id: 'rapport', name: 'Rapport de stage' },
  { id: 'apres', name: 'Apres' },
];

export const listTypeTuto = [
  { _id: 'text', name: 'Texte' },
  { _id: 'video', name: 'Video' },
];

export const ChecklistTypeList = [
  { id: 'tuto', name: 'Tutoriel' },
  { id: 'rapport', name: 'Rapport de stage' },
  { id: 'quizz', name: 'Quizz' },
  { id: 'autre', name: 'Autre' },
];
export const listTypeRep = ['non', 'rep', 'rep+'];

export const studentGenderType = [
  { id: 'fille', name: 'Je suis une fille' },
  { id: 'garcon', name: 'Je suis un garçon' },
  { id: 'autre', name: 'Je ne souhaite pas me prononcer' },
];

export const studentSubjectsType = [
  { id: 'francais', name: 'Français' },
  { id: 'histoire-geo', name: 'Histoire/Géographie' },
  { id: 'mathematiques', name: 'Mathématiques' },
  { id: 'langues', name: 'Langues' },
  { id: 'sport', name: 'Sport' },
  { id: 'physique', name: 'Physique' },
  { id: 'technologie', name: 'Technologie' },
  { id: 'arts-plastiques', name: 'Arts plastiques' },
  { id: 'musique', name: 'Musique' },
  { id: 'svt', name: 'SVT' },
];

export const ILE_DE_FRANCE_DEPARTEMENTS = ['75', '77', '78', '91', '92', '93', '94', '95'];

export const DEPT_RESSOURCES_NON_REP = [
  {
    _id: '01',
    name: '01 - Ain',
    ressources: [{ link: 'https://auvergne.stagedecouverte.fr/' }],
  },
  {
    _id: '02',
    name: '02 - Aisne',
    ressources: [{ link: 'https://hautsdefrance.stagedecouverte.fr/' }],
  },
  {
    _id: '03',
    name: '03 - Allier',
    ressources: [{ link: 'https://auvergne.stagedecouverte.fr/' }],
  },
  {
    _id: '04',
    name: '04 - Alpes-de-Haute-Provence',
    ressources: [
      { link: 'http://stage3eme-pour-tous.fr/' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '05',
    name: '05 - Hautes-Alpes',
    ressources: [
      { link: 'http://stage3eme-pour-tous.fr/' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '06',
    name: '06 - Alpes-Maritimes',
    ressources: [
      { link: 'http://stage3eme-pour-tous.fr/' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '07',
    name: '07 - Ardèche',
    ressources: [{ link: 'https://auvergne.stagedecouverte.fr/' }],
  },
  {
    _id: '08',
    name: '08 - Ardennes',
    ressources: [{ link: 'https://grandest.stagedecouverte.fr/' }],
  },
  {
    _id: '09',
    name: '09 - Ariège',
    ressources: [{ link: 'https://anie.laregion.fr/anie' }, { link: 'https://occitanie.stagedecouverte.fr/' }],
  },
  {
    _id: '10',
    name: '10 - Aube',
    ressources: [{ link: 'https://grandest.stagedecouverte.fr/' }],
  },
  {
    _id: '11',
    name: '11 - Aude',
    ressources: [
      { link: 'https://occitanie.stagedecouverte.fr/' },
      { link: 'https://anie.laregion.fr/anie' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '12',
    name: '12 - Aveyron',
    ressources: [{ link: 'https://anie.laregion.fr/anie' }, { link: 'https://occitanie.stagedecouverte.fr/' }],
  },
  {
    _id: '13',
    name: '13 - Bouches-du-Rhône',
    ressources: [
      { link: 'http://stage3eme-pour-tous.fr/' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '14',
    name: '14 - Calvados',
    ressources: [{ link: 'https://normandie.stagedecouverte.fr/' }, { link: 'https://calvados.stagedecouverte.fr/' }],
  },
  {
    _id: '15',
    name: '15 - Cantal',
    ressources: [{ link: 'https://www.cantal.fr/accent-jeunes/' }, { link: 'https://auvergne.stagedecouverte.fr/' }],
  },
  {
    _id: '16',
    name: '16 - Charente',
    ressources: [{ link: 'https://nouvelle-aquitaine.stagedecouverte.fr/' }],
  },
  {
    _id: '17',
    name: '17 - Charente-Maritime',
    ressources: [{ link: 'https://nouvelle-aquitaine.stagedecouverte.fr/' }],
  },
  {
    _id: '18',
    name: '18 - Cher',
    ressources: [{ link: 'https://centre-valdeloire.stagedecouverte.fr/' }],
  },
  {
    _id: '19',
    name: '19 - Corrèze',
    ressources: [{ link: 'https://nouvelle-aquitaine.stagedecouverte.fr/' }],
  },
  {
    _id: '21',
    name: "21 - Côte-d'Or",
    ressources: [{ link: 'https://bourgogne.stagedecouverte.fr/' }],
  },
  {
    _id: '22',
    name: "22 - Côtes-d'Armor",
    ressources: [{ link: 'https://bretagne.stagedecouverte.fr/' }],
  },
  {
    _id: '23',
    name: '23 - Creuse',
    ressources: [{ link: 'https://nouvelle-aquitaine.stagedecouverte.fr/' }],
  },
  {
    _id: '24',
    name: '24 - Dordogne',
    ressources: [
      {
        link: 'https://nouvelle-aquitaine.stagedecouverte.fr/users/sign_up/student?studentType=middleSchool',
      },
    ],
  },
  {
    _id: '25',
    name: '25 - Doubs',
    ressources: [{ link: 'https://bourgogne.stagedecouverte.fr/' }],
  },
  {
    _id: '26',
    name: '26 - Drôme',
    ressources: [{ link: 'https://auvergne.stagedecouverte.fr/' }],
  },
  {
    _id: '27',
    name: '27 - Eure',
    ressources: [{ link: 'https://normandie.stagedecouverte.fr/' }],
  },
  {
    _id: '28',
    name: '28 - Eure-et-Loir',
    ressources: [{ link: 'https://centre-valdeloire.stagedecouverte.fr/' }],
  },
  {
    _id: '29',
    name: '29 - Finistère',
    ressources: [{ link: 'https://bretagne.stagedecouverte.fr/' }],
  },
  {
    _id: '30',
    name: '30 - Gard',
    ressources: [
      { link: 'https://www.laregion.fr/Les-Maisons-de-l-Orientation' },
      { link: 'https://occitanie.stagedecouverte.fr/' },
    ],
  },
  {
    _id: '31',
    name: '31 - Haute-Garonne',
    ressources: [{ link: 'https://anie.laregion.fr/anie' }, { link: 'https://occitanie.stagedecouverte.fr/' }],
  },
  {
    _id: '32',
    name: '32 - Gers',
    ressources: [{ link: 'https://anie.laregion.fr/anie' }, { link: 'https://occitanie.stagedecouverte.fr/' }],
  },
  {
    _id: '33',
    name: '33 - Gironde',
    ressources: [{ link: 'https://www.gironde.fr/je-cherche-un-stage' }],
  },
  {
    _id: '34',
    name: '34 - Hérault',
    ressources: [
      { link: 'https://herault.fr/1042-stages-de-3eme-college.htm' },
      { link: 'https://herault.stagedecouverte.fr/' },
      { link: 'https://occitanie.stagedecouverte.fr/' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '35',
    name: '35 - Ille-et-Vilaine',
    ressources: [{ link: 'https://bretagne.stagedecouverte.fr/' }],
  },
  {
    _id: '36',
    name: '36 - Indre',
    ressources: [{ link: 'https://centre-valdeloire.stagedecouverte.fr/' }],
  },
  {
    _id: '37',
    name: '37 - Indre-et-Loire',
    ressources: [{ link: 'https://centre-valdeloire.stagedecouverte.fr/' }],
  },
  {
    _id: '38',
    name: '38 - Isère',
    ressources: [{ link: 'https://auvergne.stagedecouverte.fr/' }],
  },
  {
    _id: '39',
    name: '39 - Jura',
    ressources: [{ link: 'https://bourgogne.stagedecouverte.fr/' }],
  },
  {
    _id: '40',
    name: '40 - Landes',
    ressources: [{ link: 'https://nouvelle-aquitaine.stagedecouverte.fr/' }],
  },
  {
    _id: '41',
    name: '41 - Loir-et-Cher',
    ressources: [{ link: 'https://centre-valdeloire.stagedecouverte.fr/' }],
  },
  {
    _id: '42',
    name: '42 - Loire',
    ressources: [
      { link: 'https://stages-college.loire.fr/s3e/s3e/public/list.xhtml' },
      { link: 'https://auvergne.stagedecouverte.fr/' },
    ],
  },
  {
    _id: '43',
    name: '43 - Haute-Loire',
    ressources: [{ link: 'https://auvergne.stagedecouverte.fr/' }],
  },
  {
    _id: '44',
    name: '44 - Loire-Atlantique',
    ressources: [{ link: 'https://choisirmonstage-paysdelaloire.fr/' }],
  },
  {
    _id: '45',
    name: '45 - Loiret',
    ressources: [{ link: 'https://centre-valdeloire.stagedecouverte.fr/' }],
  },
  {
    _id: '46',
    name: '46 - Lot',
    ressources: [{ link: 'https://anie.laregion.fr/anie' }, { link: 'https://occitanie.stagedecouverte.fr/' }],
  },
  {
    _id: '47',
    name: '47 - Lot-et-Garonne',
    ressources: [{ link: 'https://nouvelle-aquitaine.stagedecouverte.fr/' }],
  },
  {
    _id: '48',
    name: '48 - Lozère',
    ressources: [{ link: 'https://occitanie.stagedecouverte.fr/' }],
  },
  {
    _id: '49',
    name: '49 - Maine-et-Loire',
    ressources: [{ link: 'https://choisirmonstage-paysdelaloire.fr/' }],
  },
  {
    _id: '50',
    name: '50 - Manche',
    ressources: [{ link: 'https://manche.stagedecouverte.fr/' }, { link: 'https://normandie.stagedecouverte.fr/' }],
  },
  {
    _id: '51',
    name: '51 - Marne',
    ressources: [{ link: 'https://grandest.stagedecouverte.fr/' }],
  },
  {
    _id: '52',
    name: '52 - Haute-Marne',
    ressources: [{ link: 'https://grandest.stagedecouverte.fr/' }],
  },
  {
    _id: '53',
    name: '53 - Mayenne',
    ressources: [{ link: 'https://choisirmonstage-paysdelaloire.fr/' }],
  },
  {
    _id: '54',
    name: '54 - Meurthe-et-Moselle',
    ressources: [{ link: 'https://grandest.stagedecouverte.fr/' }],
  },
  {
    _id: '55',
    name: '55 - Meuse',
    ressources: [{ link: 'https://grandest.stagedecouverte.fr/' }],
  },
  {
    _id: '56',
    name: '56 - Morbihan',
    ressources: [{ link: 'https://bretagne.stagedecouverte.fr/' }],
  },
  {
    _id: '57',
    name: '57 - Moselle',
    ressources: [{ link: 'https://grandest.stagedecouverte.fr/' }],
  },
  {
    _id: '58',
    name: '58 - Nièvre',
    ressources: [{ link: 'https://bourgogne.stagedecouverte.fr/' }],
  },
  {
    _id: '59',
    name: '59 - Nord',
    ressources: [
      { link: 'https://nordcolleges.enthdf.fr/stages/' },
      { link: 'https://hautsdefrance.stagedecouverte.fr/' },
    ],
  },
  {
    _id: '60',
    name: '60 - Oise',
    ressources: [{ link: 'https://hautsdefrance.stagedecouverte.fr/' }],
  },
  {
    _id: '61',
    name: '61 - Orne',
    ressources: [{ link: 'https://normandie.stagedecouverte.fr/' }],
  },
  {
    _id: '62',
    name: '62 - Pas-de-Calais',
    ressources: [{ link: 'https://stage3e.pasdecalais.fr/' }, { link: 'https://hautsdefrance.stagedecouverte.fr/' }],
  },
  {
    _id: '63',
    name: '63 - Puy-de-Dôme',
    ressources: [{ link: 'https://auvergne.stagedecouverte.fr/' }],
  },
  {
    _id: '64',
    name: '64 - Pyrénées-Atlantiques',
    ressources: [{ link: 'https://nouvelle-aquitaine.stagedecouverte.fr/' }],
  },
  {
    _id: '65',
    name: '65 - Hautes-Pyrénées',
    ressources: [
      { link: 'https://occitanie.stagedecouverte.fr/' },
      { link: 'https://anie.laregion.fr/anie' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '66',
    name: '66 - Pyrénées-Orientales',
    ressources: [
      { link: 'https://occitanie.stagedecouverte.fr/' },
      { link: 'https://anie.laregion.fr/anie' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '67',
    name: '67 - Bas-Rhin',
    ressources: [
      { link: 'https://stage-de-troisieme.bas-rhin.fr/Offres/Index' },
      { link: 'https://grandest.stagedecouverte.fr/' },
    ],
  },
  {
    _id: '68',
    name: '68 - Haut-Rhin',
    ressources: [
      { link: 'https://stage-de-troisieme.bas-rhin.fr/Offres/Index' },
      { link: 'https://grandest.stagedecouverte.fr/' },
    ],
  },
  {
    _id: '69',
    name: '69 - Rhône',
    ressources: [
      {
        link: 'https://observatoire.univ-lyon1.fr/science-pour-tous/scolaires/stages-de-troisieme',
      },
      { link: 'https://auvergne.stagedecouverte.fr/' },
    ],
  },
  {
    _id: '70',
    name: '70 - Haute-Saône',
    ressources: [{ link: 'https://bourgogne.stagedecouverte.fr/' }],
  },
  {
    _id: '71',
    name: '71 - Saône-et-Loire',
    ressources: [{ link: 'https://bourgogne.stagedecouverte.fr/' }],
  },
  {
    _id: '72',
    name: '72 - Sarthe',
    ressources: [{ link: 'https://choisirmonstage-paysdelaloire.fr/' }],
  },
  {
    _id: '73',
    name: '73 - Savoie',
    ressources: [{ link: 'https://auvergne.stagedecouverte.fr/' }],
  },
  {
    _id: '74',
    name: '74 - Haute-Savoie',
    ressources: [{ link: 'https://auvergne.stagedecouverte.fr/' }],
  },
  {
    _id: '75',
    name: '75 - Paris',
    ressources: [{ link: 'https://www.monstage.paris/' }, { link: 'https://iledefrance.stagedecouverte.fr/' }],
  },
  {
    _id: '76',
    name: '76 - Seine-Maritime',
    ressources: [
      { link: 'https://normandie.stagedecouverte.fr/' },
      { link: 'https://seine-maritime.stagedecouverte.fr/' },
    ],
  },
  {
    _id: '77',
    name: '77 - Seine-et-Marne',
    ressources: [{ link: 'https://iledefrance.stagedecouverte.fr/' }],
  },
  {
    _id: '78',
    name: '78 - Yvelines',
    ressources: [{ link: 'https://iledefrance.stagedecouverte.fr/' }],
  },
  {
    _id: '79',
    name: '79 - Deux-Sèvres',
    ressources: [{ link: 'https://nouvelle-aquitaine.stagedecouverte.fr/' }],
  },
  {
    _id: '80',
    name: '80 - Somme',
    ressources: [{ link: 'https://hautsdefrance.stagedecouverte.fr/' }],
  },
  {
    _id: '81',
    name: '81 - Tarn',
    ressources: [
      { link: 'https://occitanie.stagedecouverte.fr/' },
      { link: 'https://anie.laregion.fr/anie' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '82',
    name: '82 - Tarn-et-Garonne',
    ressources: [{ link: 'https://anie.laregion.fr/anie' }, { link: 'https://occitanie.stagedecouverte.fr/' }],
  },
  {
    _id: '83',
    name: '83 - Var',
    ressources: [
      { link: 'http://stage3eme-pour-tous.fr/' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '84',
    name: '84 - Vaucluse',
    ressources: [
      { link: 'http://stage3eme-pour-tous.fr/' },
      {
        link: 'https://www.fntp.fr/metiers/stage-alternance/trouver-contrat-stage/offres?field_type_de_formation_tid=632&field_type_de_metier_tid=All&type_liste=carte',
      },
    ],
  },
  {
    _id: '85',
    name: '85 - Vendée',
    ressources: [{ link: 'https://choisirmonstage-paysdelaloire.fr/' }],
  },
  {
    _id: '86',
    name: '86 - Vienne',
    ressources: [{ link: 'https://nouvelle-aquitaine.stagedecouverte.fr/' }],
  },
  {
    _id: '87',
    name: '87 - Haute-Vienne',
    ressources: [{ link: 'https://nouvelle-aquitaine.stagedecouverte.fr/' }],
  },
  {
    _id: '88',
    name: '88 - Vosges',
    ressources: [{ link: 'https://grandest.stagedecouverte.fr/' }],
  },
  {
    _id: '89',
    name: '89 - Yonne',
    ressources: [{ link: 'https://bourgogne.stagedecouverte.fr/' }],
  },
  {
    _id: '90',
    name: '90 - Territoire de Belfort',
    ressources: [{ link: 'https://bourgogne.stagedecouverte.fr/' }],
  },
  {
    _id: '91',
    name: '91 - Essonne',
    ressources: [
      { link: 'https://iledefrance.stagedecouverte.fr/' },
      { link: 'http://stages3e.essonne.fr/stages3e/s3e/' },
    ],
  },
  {
    _id: '92',
    name: '92 - Hauts-de-Seine',
    ressources: [{ link: 'https://iledefrance.stagedecouverte.fr/' }],
  },
  {
    _id: '93',
    name: '93 - Seine-Saint-Denis',
    ressources: [
      { link: 'https://iledefrance.stagedecouverte.fr/' },
      { link: 'https://monstagede3e.seinesaintdenis.fr/s3e/' },
    ],
  },
  {
    _id: '94',
    name: '94 - Val-de-Marne',
    ressources: [{ link: 'https://iledefrance.stagedecouverte.fr/' }],
  },
  {
    _id: '95',
    name: "95 - Val-d'Oise",
    ressources: [
      {
        link: 'https://actions-educatives.valdoise.fr/actions-thematiques/liens-collegiens-monde-professionnel/bourse-departementale-de-stages-collegiens-en-entreprise',
      },
      { link: 'https://iledefrance.stagedecouverte.fr/' },
    ],
  },
  {
    _id: '971',
    name: '971 - Guadeloupe',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '972',
    name: '972 - Martinique',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '973',
    name: '973 - Guyane',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '974',
    name: '974 - La Réunion',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '975',
    name: '975 - St-Pierre-et-Miquelon',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '976',
    name: '976 - Mayotte',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '977',
    name: '977 - Saint-Barthélémy',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '978',
    name: '978 - Saint-Martin',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '986',
    name: '986 - Wallis et Futuna',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '987',
    name: '987 - Polynésie Française',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '988',
    name: '988 - Nouvelle Calédonie',
    ressources: [{ link: 'https://www.monstagedetroisieme.fr/' }],
  },
  {
    _id: '2A',
    name: '2A - Corse-du-Sud',
    ressources: [
      {
        link: 'https://corse.stage-agricole.com/cat/recherche-offre-de-stage-en-agriculture/',
      },
    ],
  },
  {
    _id: '2B',
    name: '2B - Haute-Corse',
    ressources: [
      {
        link: 'https://corse.stage-agricole.com/cat/recherche-offre-de-stage-en-agriculture/',
      },
    ],
  },
];

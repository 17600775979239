import React from 'react';
import { Context } from '../../context/Context';
import StudentApplicationsListView from './StudentApplications/StudentApplicationsListView';
import StudentProfile from './StudentProfil/StudentProfile';
import { useParams } from 'react-router-dom';

export const StudentApplicationsPage: React.FunctionComponent<{}> = () => {

    // STATES

    // HOOKS
    const { id } = useParams();

    return (
        <div>
            <StudentProfile studentId={id} />
            <StudentApplicationsListView studentId={id} />
        </div>
    )
}

export default StudentApplicationsPage;

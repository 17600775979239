
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';
import { WrappedResponse } from '../../InternshipOffersPage/InternshipOffersPageService';
import CircularLoader from '../../Loader/CircularLoader';
import useStudentApplicationGet, { Application } from './GetStudentApplicationsService';
import { BusinessCenter, GetApp } from '@material-ui/icons';
import { format } from 'date-fns';
import DescriptionIcon from '@material-ui/icons/Description';
import TodayIcon from '@material-ui/icons/Today';
import CustomStatusLabel from '../../Miscellaneous/CustomStatusLabel';
import MotivationLetterModal from '../../Modal/MotivationLetterModal';
import { Context } from '../../../context/Context';

const styles: StyleRules = {
  title: {
    padding: "15px",
    fontSize: "28px",
    textTransform: "uppercase",
  },
  contentFullW: {
    padding: "5px 0",
    marginBottom: "15px",
    "@media screen and (min-width: 960px)": {
      padding: "18px 0 12px 135px",
      transform: "translateX(-120px)",
      width: '100%',
      maxWidth: '100%',
      marginLeft: '0',
      "&::after": {
        position: "absolute",
        display: "block",
        content: "''",
        width: "calc(100vw)",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#FFF",
        zIndex: -1
      }
    }
  },
  iconAlign: {
    display: "flex",
    alignItems: "center"
  },
  textPadding: {
    padding: "0 0 0 10px"
  },
  button: {
    display: "flex",
    fontWeight: 500,
    padding: '6px 12px',
    margin: "0px 0px 7px 0px",
    borderRadius: '4px',
    backgroundColor: '#6ac0b6',
    '&:hover': {
      backgroundColor: '#49aea2',
      borderColor: '#49aea2',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#6ac0b6',
      borderColor: '#6ac0b6',
    },
  }
}

const BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#6ac0b6',
    borderColor: '#6ac0b6',
    '&:hover': {
      backgroundColor: '#49aea2',
      borderColor: '#49aea2',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#6ac0b6',
      borderColor: '#6ac0b6',
    },
  },
})(Button);

interface ViewProps extends WithStyles<typeof styles> {
  studentApplicationsResponse: WrappedResponse<Application[]>,
  studentId: string,
  context: {
    state: {
      user: {
        account: {
          type: string
        }
      },
    },
  },
}

// Component with injected styles
const StudentApplicationsListView: React.FunctionComponent<ViewProps> = ({ studentApplicationsResponse, classes, context, studentId }: ViewProps) => {
  const [isOpenMotivationLetterModal, setIsOpenMotivationLetterModal] = React.useState(false);
  const [selectedApplication, setSelectedApplication] = React.useState();

  const handleClickOpenLetter = (application) => () => {
    setIsOpenMotivationLetterModal(true);
    setSelectedApplication(application);
  };

  const handleClose = () => {
    setIsOpenMotivationLetterModal(false);
  };

  const isCollegeUser = () => {
    return context.state.user.account.type === 'college';
  }

  return (
    <div>
      <div className={classes.title}>Candidatures</div>
      {isOpenMotivationLetterModal && <MotivationLetterModal application={selectedApplication} open={isOpenMotivationLetterModal} handleClose={handleClose} />}
      {studentApplicationsResponse.status === 'loading' && <CircularLoader />}
      {studentApplicationsResponse.status === 'success' &&
        <div>
          {studentApplicationsResponse.payload.map((a: Application) => {
            return (
              <div key={a._id}>
                <Paper elevation={0} className={classes.contentFullW}>
                  <Grid container spacing={10}>
                    <Grid item xs={12} sm container>
                      <Grid item xs>
                        <Typography gutterBottom>
                          {a.offer.title}
                        </Typography>
                        <Typography className={classes.iconAlign} style={{ color: "#6ac0b6" }} >
                          <BusinessCenter fontSize="small" />
                          <span className={classes.textPadding}>{a.offer.company.name}</span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs>
                        {isCollegeUser() &&
                          <Grid container>
                            {a.motivation_letter_url ? (
                              <a
                                className={classes.button}
                                download={`lettre_de_motivation.pdf`}
                                href={a.motivation_letter_url}>
                                <GetApp style={{ color: "#FFFFFF", margin: '0px 5px 0px -4px' }} fontSize="small" />
                                <div style={{ color: "#FFFFFF" }}>Télécharger la lettre de motivation</div>
                              </a>

                            ) : (<BootstrapButton
                              style={{ margin: '0px 0px 7px 0px' }}
                              variant="contained"
                              color="primary"
                              disableRipple
                              startIcon={<DescriptionIcon />}
                              onClick={handleClickOpenLetter(a)}
                            >
                              Voir la lettre de motivation
                            </BootstrapButton>)}
                          </Grid>
                        }
                        <Typography gutterBottom className={classes.iconAlign}>
                          <TodayIcon fontSize="small" />
                          <span className={classes.textPadding}>Créée le {format(new Date(a.date), "dd/MM/yy")}</span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs>
                        <CustomStatusLabel status={a.status} declinedReason={a.declined_reason} studentId={studentId} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </div>)
          })}
        </div>
      }
    </div>
  )
}

// Export for unit test only
export const StyledStudentApplicationsListView = withStyles(styles)(StudentApplicationsListView);

interface IProps {
  studentId: string,
}

// Component with hook
const WrappedStudentApplicationsListView: React.FunctionComponent<IProps> = ({ studentId }: IProps) => {
  // HOOKS
  const studentApplicationsResponse: WrappedResponse<Application[]> = useStudentApplicationGet(studentId)

  return (
    <Context.Consumer>
      {(context) => <StyledStudentApplicationsListView studentApplicationsResponse={studentApplicationsResponse} context={context} studentId={studentId} />}
    </Context.Consumer>
  )
}

export default WrappedStudentApplicationsListView;
import React from 'react';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

import style from './style/style';

const LinearLoader = ({ classes }) => {
  return (
    <div className={classes.containerWrapper}>
      <LinearProgress
        mode='indeterminate'
        classes={{
          root: classes.root,
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary,
        }}
      />
    </div>
  );
};

export default withStyles(style)(LinearLoader);

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import { nl2br } from '../../helpers/helpers';

const MotivationLetterModal = ({ open, application, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'>
      <DialogTitle id='scroll-dialog-title'>Lettre de motivation</DialogTitle>
      <DialogContent dividers={true}>
        <div
          dangerouslySetInnerHTML={{
            __html: nl2br(application.motivation_letter),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MotivationLetterModal;

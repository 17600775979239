import React, { Component } from 'react';
import { Context } from './Context';

class ContextProvider extends Component {
  state = {
    //user: {},
    snackbar: {},
    additional_informations_later: false,
  };

  componentDidMount() {
    this.setState({
      additional_informations_later: Boolean(localStorage.getItem('additionalInformationLater')) || false,
    });
  }

  updateUser = (user) => this.setState({ user });

  updateAdditionalInformationLaterState = (state) => {
    localStorage.setItem('additionalInformationLater', state ? '1' : '0');
    this.setState({ additional_informations_later: state });
  };

  // function related to errors
  // snackbar should contain isSnackbar (bool), status, message, timeout
  handleSnackbar = (snackbar) => {
    if (this.state.snackbar && this.state.snackbar.isSnackbar) {
      clearTimeout(this.state.snackbar.timeoutId);
    }

    let timeout = snackbar.timeout ? snackbar.timeout : 5000;

    const timeoutId = setTimeout(() => {
      this.closeSnackbar();
    }, timeout);

    this.setState({ snackbar: { ...snackbar, isSnackbar: true, timeoutId } });
  };

  closeSnackbar = () => {
    if (this.state.snackbar && this.state.snackbar.isSnackbar) {
      clearTimeout(this.state.snackbar.timeoutId);
    }

    this.setState({ snackbar: { isSnackbar: false } });
  };

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          updateUser: this.updateUser,
          updateAdditionalInformationLaterState: this.updateAdditionalInformationLaterState,
          handleSnackbar: this.handleSnackbar,
          closeSnackbar: this.closeSnackbar,
        }}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default ContextProvider;

import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import Settings from '@material-ui/icons/Settings';
import AddAlert from '@material-ui/icons/AddAlert';
import GridContainer from '../../components/Grid/GridContainer';
import ItemGrid from '../../components/Grid/ItemGrid';
import IconCard from '../../components/Cards/IconCard';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import Snackbar from '../../components/Snackbar/Snackbar';
import ErrorBoundaries from '../../components/Wrapper/ErrorBoundaries';
import profilPageStyle from '../../views/Common/style/profilPageStyle';
import config from '../../config';
import { Context } from '../../context/Context';
import FormHOC from '../../hoc/FormHOC';
import SearchBar from '../../components/SearchBar/SearchBar';


type Snackbar = {
  status?: string,
  message?: string,
  isSnackbar?: boolean,
  timeout?: number
}
interface IProps extends WithStyles<typeof profilPageStyle> {
  context: {
    state: {
      user: {
        _id: string,
        account: {
          type: string
        }
      },
      snackbar: Snackbar
    },
    handleSnackbar: (snackbar: Snackbar) => void
    closeSnackbar: () => void
  },
  location: {
    state: {
      user: {
        _id: string
      },
      redirectUrl: string
    }
  },
  handleChangeAddress: any,
  handleSelectAddress: any,
  addressState: string,
  address: string,
  associationUrlState: any,
  associationUrl: any,
  handleOnChange: any,
  handleOnSubmit: (arr: string[]) => boolean,
}

const InfoAsso: React.FC<IProps> = ({ context, classes, location, handleChangeAddress, handleSelectAddress, addressState, address, associationUrlState, associationUrl, handleOnChange, handleOnSubmit }: IProps) => {
  //STATES
  const [assoName, setAssoName] = useState();

  // HOOKS
  useEffect(() => {
    getAsso();
  }, [setAssoName]);

  const getAsso = async (): Promise<void> => {
    const isAdmin = context.state.user.account.type === 'administrator';
    const user = isAdmin ? location.state.user : context.state.user;

    try {
      const response = await axios.get(
        `${config.API_URL}/api/users/association/${user._id}/info`
      );
      setAssoName(response.data.name.split('à')[0]);
      handleSelectAddress(response.data.city);
      handleOnChange(response.data.url, 'associationUrl', 'optionnalUrl');

    } catch (err) {
      context.handleSnackbar({
        status: 'danger',
        message: 'Oups.. Un problème est survenu'
      });
    }
  };

  const updateAsso = async (): Promise<void> => {
    const isAdmin = context.state.user.account.type === 'administrator';
    const user = isAdmin ? location.state.user : context.state.user;
    const validations = [
      ...['address'],
      ...(associationUrl ? ["associationUrl"] : [])
    ];
    if (!handleOnSubmit(validations)) return null;

    try {
      await axios.put(`${config.API_URL}/api/users/association/${user._id}/info`, {
        url: associationUrl,
        city: address,
      });

      context.handleSnackbar({
        status: 'success',
        message: "Les informations de l'association ont été validées avec succès"
      });

    } catch (err) {
      context.handleSnackbar({
        status: 'danger',
        message: 'Oups.. Un problème est survenu'
      });
    }
  };

  return (
    <ErrorBoundaries>
      {context.state.snackbar &&
        context.state.snackbar.isSnackbar && (
          <Snackbar
            key='snackbar'
            place='tr'
            color={context.state.snackbar.status}
            icon={AddAlert}
            message={context.state.snackbar.message}
            open={true}
            closeNotification={context.closeSnackbar}
            close
          />
        )}
      <GridContainer justifyContent='center'>
        <ItemGrid xs={12}>
          <IconCard
            icon={Settings}
            title='Informations association'
            iconColor='pink'
            content={
              <form style={{ marginTop: '5vh' }}>
                <GridContainer>
                  <ItemGrid xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Association
                  </FormLabel>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={7}>
                    <CustomInput
                      id='asso'
                      value={assoName}
                      inputProps={{
                        readOnly: true
                      }}
                    />
                  </ItemGrid>

                  <ItemGrid xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Ville
                  </FormLabel>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={7}>
                    <SearchBar
                      success={addressState === "success"}
                      error={addressState === "error"}
                      value={address}
                      type={"(cities)"}
                      handleChange={handleChangeAddress}
                      handleSelect={handleSelectAddress}
                      noLabel
                    />
                  </ItemGrid>

                  <ItemGrid xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Lien Internet
                  </FormLabel>
                  </ItemGrid>
                  <ItemGrid xs={12} sm={7}>
                    <CustomInput
                      key='inputUrl'
                      success={associationUrlState === 'success'}
                      error={associationUrlState === 'error'}
                      value={associationUrl}
                      helpText='Exemple: www.viensvoirmontaf.fr'
                      id='association-url'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) =>
                          handleOnChange(e.target.value, 'associationUrl', 'optionnalUrl')
                      }}
                    />
                  </ItemGrid>
                </GridContainer>
              </form>
            }
            footerAlign='center'
            footer={
              <Fragment>
                {addressState === "success" && (associationUrlState === "success" || associationUrlState === undefined) ?
                <Button
                  color='primary'
                  onClick={updateAsso}
                  customClass={classes.footerButton}>
                  Valider
              </Button> :
                  <Button
                  disabled
                  customClass={classes.footerButton}>
                  Valider
            </Button>}
              </Fragment>
            }
          />
        </ItemGrid>
      </GridContainer>
    </ErrorBoundaries>
  );
};

const WrappedComponent = FormHOC(InfoAsso);

export default withStyles(profilPageStyle)((props) => (
  <Context.Consumer>
    {(context) => <WrappedComponent {...props} context={context} />}
  </Context.Consumer>
));
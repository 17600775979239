// ##############################
// // // Button styles
// #############################

import {
  lightGrayColor,
  grayColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  orangeColor,
  button,
  blackColor,
} from '../../../assets/styles/jss/main';

const buttonStyle = {
  button,
  fullWidth: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  primary: {
    backgroundColor: primaryColor,
    boxShadow:
      '0 2px 2px 0 rgba(91, 196, 186, 0.14), 0 3px 1px -2px rgba(91, 196, 186, 0.2), 0 1px 5px 0 rgba(91, 196, 186, 0.12)',
    '&:hover': {
      backgroundColor: primaryColor,
      boxShadow:
        '0 14px 26px -12px rgba(91, 196, 186, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(91, 196, 186, 0.2)',
    },
  },
  info: {
    backgroundColor: infoColor,
    boxShadow:
      '0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12)',
    '&:hover': {
      backgroundColor: infoColor,
      boxShadow:
        '0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2)',
    },
  },
  success: {
    backgroundColor: successColor,
    boxShadow:
      '0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)',
    '&:hover': {
      backgroundColor: successColor,
      boxShadow:
        '0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)',
    },
  },
  warning: {
    backgroundColor: warningColor,
    boxShadow:
      '0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)',
    '&:hover': {
      backgroundColor: warningColor,
      boxShadow:
        '0 14px 26px -12px rgba(255, 152, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2)',
    },
  },
  danger: {
    backgroundColor: dangerColor,
    boxShadow:
      '0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12)',
    '&:hover': {
      backgroundColor: dangerColor,
      boxShadow:
        '0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)',
    },
  },
  lightGray: {
    backgroundColor: lightGrayColor,
    color: blackColor,
    '&:hover': {
      backgroundColor: lightGrayColor,
      color: blackColor,
    },
  },
  orange: {
    backgroundColor: orangeColor,
    boxShadow:
      '0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12)',
    '&:hover': {
      backgroundColor: orangeColor,
      boxShadow:
        '0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)',
    },
  },
  defaultNoBackground: {
    '&,&:hover,&:focus': {
      color: grayColor,
      boxShadow: 'none',
      backgroundColor: grayColor,
    },
  },
  primaryNoBackground: {
    '&,&:hover,&:focus': {
      color: primaryColor,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  infoNoBackground: {
    '&,&:hover,&:focus': {
      color: infoColor,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  successNoBackground: {
    '&,&:hover,&:focus': {
      color: successColor,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  warningNoBackground: {
    '&,&:hover,&:focus': {
      color: warningColor,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  dangerNoBackground: {
    '&,&:hover,&:focus': {
      color: dangerColor,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  defaultBordered: {
    '&,&:hover,&:focus': {
      border: '1px solid #ccced0',
      color: '#111',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  primaryBordered: {
    '&,&:hover,&:focus': {
      border: `1px solid ${primaryColor}`,
      color: primaryColor,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  white: {
    '&,&:focus,&:hover': {
      backgroundColor: '#FFFFFF',
      color: primaryColor,
      border: `1px solid ${primaryColor}`,
      '&:hover': {
        boxShadow:
          '0 14px 26px -12px rgba(91, 196, 186, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(91, 196, 186, 0.2)',
      },
    },
  },
  simple: {
    '&,&:focus,&:hover': {
      backgroundColor: 'transparent',
      color: grayColor,
      boxShadow: 'none',
    },
  },
  transparent: {
    '&,&:focus,&:hover': {
      color: 'inherit',
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  round: {
    borderRadius: '9999px',
  },
  disabled: {
    opacity: '0.65',
    cursor: 'not-allowed',
  },
  right: {
    float: 'right',
  },
  justIcon: {
    minWidth: '10px',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '20px',
  },
  wd: {
    minWidth: '160px',
  },
  lg: {
    fontSize: '14px',
    padding: '18px 36px',
  },
  sm: {
    padding: '5px 20px',
    fontSize: '11px',
  },
  xs: {
    padding: '4px 15px',
    fontSize: '10px',
  },
  facebook: {
    backgroundColor: '#3b5998',
    color: '#fff',
    /*boxShadow:
      "0 2px 2px 0 rgba(59, 89, 152, 0.14), 0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12)",*/
    '&:hover,&:focus': {
      backgroundColor: '#3b5998',
      color: '#fff',
      /*boxShadow:
        "0 14px 26px -12px rgba(59, 89, 152, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(59, 89, 152, 0.2)"*/
    },
  },
  facebookNoBackground: {
    '&,&:hover,&:focus': {
      color: '#3b5998',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  google: {
    backgroundColor: '#dd4b39',
    color: '#fff',
    boxShadow:
      '0 2px 2px 0 rgba(221, 75, 57, 0.14), 0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12)',
    '&:hover,&:focus': {
      backgroundColor: '#dd4b39',
      color: '#fff',
      boxShadow:
        '0 14px 26px -12px rgba(221, 75, 57, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(221, 75, 57, 0.2)',
    },
  },
  googleNoBackground: {
    '&,&:hover,&:focus': {
      color: '#dd4b39',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  btnMessages: {
    marginRight: 25,
  },
  noBorder: {
    border: 'none !important',
    boxShadow: 'none',
    '&:hover,&:focus': {
      border: 'none !important',
      boxShadow: 'none',
    },
  },
  resetPadding: {
    padding: '8px 16px',
  },
};

export default buttonStyle;

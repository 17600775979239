// ##############################
// // // CustomInput styles
// #############################

import {
  whiteColor,
  blackColor,
  dangerColor,
  successColor,
  lightGrayColor,
  defaultFont,
  helpText,
} from '../../../assets/styles/jss/main';

const customInputStyle = {
  // overriding material-ui
  root: {
    border: `1px solid ${lightGrayColor}`,
    background: '#f6f6f9',
    borderRadius: '5px',
  },
  rootWithMultiLine: {
    border: '2px solid #ececee',
    '& div:first-child': {
      minHeight: 30,
    },
    '& textarea:not([readOnly])': {
      border: 'none',
      width: 'calc(100% - 10px)',
      height: '100%',
      margin: '-5px 0 0 -5px',
    },
  },
  input: {
    position: 'relative',
    padding: '2px 25px 2px 12px',
    fontWeight: '400',
    height: '36px',
    fontSize: '16px',
    lineHeight: '1.428571429',
    color: blackColor,
    textOverflow: 'ellipsis',
    border: '2px solid #ececee',
    '&[rows]': {
      height: 'auto',
    },
  },
  inputError: {
    borderColor: `${dangerColor} !important`,
  },
  inputSuccess: {
    borderColor: `${successColor} !important`,
  },
  disabled: {
    '&:before': {
      background: 'transparent !important',
      backgroundSize: '3px 1px !important',
      backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.23) 33%, transparent 0%) !important',
      backgroundRepeat: 'repeat-x !important',
      backgroundPosition: 'left top !important',
    },
  },
  //
  labelRoot: {
    ...defaultFont,
    position: 'relative !important',
    color: `${blackColor}!important`,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    transform: 'scale(1)',
    '& + div': {
      marginTop: 10,
    },
  },
  labelRootError: {
    color: `${dangerColor}!important`,
  },
  labelRootSuccess: {
    color: `${successColor}!important`,
  },
  feedback: {
    position: 'absolute',
    bottom: '20px',
    right: '5px',
    zIndex: '2',
    display: 'block',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  feedbackNoLabel: {
    top: '10px',
  },
  feedbackWithHelp: {
    bottom: 0,
    top: 10,
  },
  passwordEyeWrapper: {
    margin: 0,
  },
  passwordEye: {
    position: 'absolute',
    bottom: 10,
    right: 5,
    zIndex: '2',
    display: 'block',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    cursor: 'pointer',
    fill: '#cecdd6',
  },
  inputRTL: {
    textAlign: 'right',
  },
  inputWithAdornment: {
    paddingTop: '21px',
  },
  formControl: {
    paddingBottom: '10px',
    position: 'relative',
  },
  labelWithAdornment: {
    top: '3px',
  },
  feedbackAdorment: {
    right: '22px',
  },
  helpText: {
    ...helpText,
  },
  transparent: {
    background: 'transparent',
    border: 'none',
  },
  white: {
    background: whiteColor,
    border: 'none',
  },
  clickable: {
    cursor: 'pointer',
  },
  decimal_1: {
    maxWidth: 24,
  },
  decimal_2: {
    maxWidth: 33,
  },
  decimal_3: {
    maxWidth: 42,
  },
  decimal_4: {
    maxWidth: 51,
  },
  decimal_5: {
    maxWidth: 60,
  },
  decimal_6: {
    maxWidth: 70,
  },
  decimal_7: {
    maxWidth: 80,
  },
  decimal_8: {
    maxWidth: 90,
  },
  decimal_9: {
    maxWidth: 100,
  },
  decimal_10: {
    maxWidth: 105,
  },
  feedBackWithDecimal_1: {
    top: 'unset',
    bottom: 20,
    left: 40,
  },
  feedBackWithDecimal_2: {
    top: 'unset',
    bottom: 20,
    left: 45,
  },
  feedBackWithDecimal_3: {
    top: 'unset',
    bottom: 20,
    left: 55,
  },
  feedBackWithDecimal_4: {
    top: 'unset',
    bottom: 20,
    left: 65,
  },
  feedBackWithDecimal_5: {
    top: 'unset',
    bottom: 20,
    left: 75,
  },
  feedBackWithDecimal_6: {
    top: 'unset',
    bottom: 20,
    left: 85,
  },
  feedBackWithDecimal_7: {
    top: 'unset',
    bottom: 20,
    left: 95,
  },
  feedBackWithDecimal_8: {
    top: 'unset',
    bottom: 20,
    left: 105,
  },
  feedBackWithDecimal_9: {
    top: 'unset',
    bottom: 20,
    left: 115,
  },
  feedBackWithDecimal_10: {
    top: 'unset',
    bottom: 20,
    left: 120,
  },
};

export default customInputStyle;

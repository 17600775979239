// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260;

const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
};

const containerFluid = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  '&:before,&:after': {
    display: 'table',
    content: '" "',
  },
  '&:after': {
    clear: 'both',
  },
  /*"@media (max-width: 768px)": {
    paddingRight: 0,
    paddingLeft: 0
  }*/
};

const containerOnlyMobile = {
  '@media screen and (max-width: 960px)': {
    ...containerFluid,
  },
};

const container = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  '@media (min-width: 768px)': {
    width: '750px',
  },
  '@media (min-width: 992px)': {
    width: '970px',
  },
  '@media (min-width: 1200px)': {
    width: '1170px',
  },
  '&:before,&:after': {
    display: 'table',
    content: '" "',
  },
  '&:after': {
    clear: 'both',
  },
};

const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
};

const card = {
  display: 'inline-block',
  position: 'relative',
  width: '100%',
  //margin: '25px 0',
  //boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  //borderRadius: "0px",
  color: 'rgba(0, 0, 0, 0.87)',
  background: '#fff',
  // marginBottom: 40
};

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '300',
  lineHeight: '1.5',
};

const customFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '700',
  lineHeight: '1.5', // em
};

const primaryColor = '#6ac0b6';
const redColor = '#ef4c31';
const yellowColor = '#fcb315';
const purpleColor = '#413091';
const orangeColor = '#E67337';
const pinkColor = '#b22672';

//R           // J       //B        // V
//'#ef4c31', '#fcb315', '#413091', '#b22672'

const whiteColor = '#FFFFFF';
const grayColor = '#999999';
const lightGrayColor = '#F6F6F9';
const blackColor = '#000000';

const warningColor = '#ff9800';
const dangerColor = '#f44336';
const successColor = '#3ca455';
const infoColor = '#00acc1';

// const roseColor = '#e91e63'
// const primaryBoxShadow = {
//   boxShadow:
//     '0 12px 20px -10px rgba(91, 196, 186, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(91, 196, 186, 0.2)'
// }
// const infoBoxShadow = {
//   boxShadow:
//     '0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)'
// }
// const successBoxShadow = {
//   boxShadow:
//     '0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)'
// }
// const warningBoxShadow = {
//   boxShadow:
//     '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)'
// }
// const dangerBoxShadow = {
//   boxShadow:
//     '0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)'
// }
// const roseBoxShadow = {
//   boxShadow:
//     '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)'
// }

const primaryCardHeader = {
  background: primaryColor,
};
const redCardHeader = {
  background: redColor, //'linear-gradient(60deg, #ffa726, #fb8c00)',
};
const yellowCardHeader = {
  background: yellowColor, //'linear-gradient(60deg, #66bb6a, #43a047)',
};
const purpleCardHeader = {
  background: purpleColor, //'linear-gradient(60deg, #ef5350, #e53935)',
};
const pinkCardHeader = {
  background: pinkColor, //'linear-gradient(60deg, #26c6da, #00acc1)',
};

const cardActions = {
  margin: '0 20px 10px',
  paddingTop: '10px',
  borderTop: '1px solid #eeeeee',
  height: 'auto',
  ...defaultFont,
};

const cardHeader = {
  //margin: "-20px 40px 0", // '-20px 15px 0' // '-20px 20px 0'
  borderRadius: '0px',
  padding: '15px',
};

const cardHeaderBig = {
  float: 'left',
  borderRadius: '0px',
  padding: '15px',
  margin: '-40px 40px 0',
};

const helpText = {
  color: 'rgba(0, 0, 0, 0.54)',
  margin: 0,
  fontSize: '0.75rem',
  textAlign: 'left',
  marginTop: 8,
  minHeight: '1em',
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  lineHeight: '1em',
};

const defaultBoxShadow = {
  border: '0',
  borderRadius: '3px',
  boxShadow:
    '0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  padding: '10px 0',
  transition: 'all 150ms ease 0s',
};

const tooltip = {
  padding: '10px 15px',
  minWidth: '130px',
  color: '#FFFFFF',
  lineHeight: '1.7em',
  background: 'rgba(85,85,85,0.9)',
  border: 'none',
  borderRadius: '3px',
  opacity: '1!important',
  boxShadow:
    '0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
  maxWidth: '200px',
  textAlign: 'center',
  fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  textShadow: 'none',
  textTransform: 'none',
  letterSpacing: 'normal',
  wordBreak: 'normal',
  wordSpacing: 'normal',
  wordWrap: 'normal',
  whiteSpace: 'normal',
  lineBreak: 'auto',
};

const button = {
  minWidth: 'auto',
  minHeight: 'auto',
  backgroundColor: grayColor,
  color: '#FFFFFF',
  boxShadow:
    '0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)',
  border: 'none',
  borderRadius: '4px',
  position: 'relative',
  padding: '12px 30px',
  margin: '10px 1px',
  fontSize: '1rem',
  fontWeight: '700',
  letterSpacing: '0',
  willChange: 'box-shadow, transform',
  transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  lineHeight: '1.3',
  textAlign: 'center',
  verticalAlign: 'middle',
  touchAction: 'manipulation',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: grayColor,
    color: '#FFFFFF',
    boxShadow:
      '0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)',
  },
};

const primaryBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',
};
const infoBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)',
};
const successBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)',
};
const warningBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)',
};
const dangerBoxShadow = {
  boxShadow:
    '0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)',
};
const roseBoxShadow = {
  boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)',
};

const orangeCardHeader = {
  background: yellowColor,
  //'linear-gradient(60deg, #ffa726, #fb8c00)',
  //...warningBoxShadow
};
const greenCardHeader = {
  background: primaryColor,
  //'linear-gradient(60deg, #66bb6a, #43a047)',
  //...successBoxShadow
};
// const redCardHeader = {
//   background: redColor,
//   //"linear-gradient(60deg, #ef5350, #e53935)",
//   ...dangerBoxShadow
// }
const blueCardHeader = {
  background: purpleColor,
  //'linear-gradient(60deg, #26c6da, #00acc1)',
  //...infoBoxShadow
};
// const purpleCardHeader = {
//   background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
//   ...primaryBoxShadow
// };
const roseCardHeader = {
  background: 'linear-gradient(60deg, #ec407a, #d81b60)',
  ...roseBoxShadow,
};

export {
  //variables
  orangeCardHeader,
  greenCardHeader,
  purpleCardHeader,
  roseCardHeader,
  blueCardHeader,
  button,
  drawerWidth,
  transition,
  container,
  containerFluid,
  containerOnlyMobile,
  boxShadow,
  card,
  defaultFont,
  customFont,
  primaryColor,
  redColor,
  orangeColor,
  yellowColor,
  purpleColor,
  pinkColor,
  whiteColor,
  grayColor,
  lightGrayColor,
  blackColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  primaryCardHeader,
  redCardHeader,
  yellowCardHeader,
  pinkCardHeader,
  cardActions,
  cardHeader,
  cardHeaderBig,
  defaultBoxShadow,
  tooltip,
  helpText,
};

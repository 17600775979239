import { initializeApp } from 'firebase/app';
import config from './config';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

export const initializeFirebaseApp = async () => {
  let firebaseApp = initializeApp(config.FIREBASE);

  const messaging = getMessaging(firebaseApp);

  const token = await getToken(messaging, {
    vapidKey: 'BPMlfVEbODTXluUl8fao1c1jnGICptcGhs2RrVBGA4zxAkSvobcy7mTNurI8yUTQD4m0tnANw1Z-Ek8xVZgspKs',
  });
  onMessage(messaging, (payload) => {
    // Ignore notification when app run front stage
    console.log(`message -> ${JSON.stringify(payload)}`);
  });

  return token;
};

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { uuidv4 } from '../../helpers/helpers';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

// @material-ui/icons
import Check from '@material-ui/icons/Check';

import customInputStyle from './style/customInputStyle';

const CustomInput = (props) => {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    helpText,
    value,
    type,
    nbDecimal,
    autoFocus,
    transparent,
    white,
  } = props;

  let formControlClasses = classes.formControl;
  if (formControlProps !== undefined) {
    formControlClasses += ` ${formControlProps.className}`;
  }

  if (inputProps !== undefined) {
    formControlClasses = `${formControlClasses} ${cx({
      [classes.inputWithAdornment]:
        (inputProps.startAdornment !== undefined || inputProps.endAdornment !== undefined) && labelText === undefined,
    })}`;
  }

  const successClasses = cx({
    [classes.feedback]: true,
    [classes.labelRootSuccess]: true,
    [classes.feedbackNoLabel]: labelText === undefined,
    [classes.feedbackAdorment]: inputProps !== undefined && inputProps.endAdornment !== undefined,
    [classes[`feedBackWithDecimal_${nbDecimal}`]]: nbDecimal > 0,
    [classes.feedbackWithHelp]: helpText !== undefined,
  });

  const input = cx({
    [classes.root]: true,
    [classes.input]: true,
    [classes.inputNoLabel]: labelText === undefined,
    [classes.transparent]: transparent,
    [classes.white]: white,
    [classes[`decimal_${nbDecimal}`]]: nbDecimal > 0,
    [classes.clickable]: inputProps && inputProps.readOnly && inputProps.onClick,
    [classes.inputSuccess]: success && !error,
    [classes.inputError]: error && !success,
  });

  return (
    <FormControl {...formControlProps} className={formControlClasses} aria-describedby={`${id}-text`}>
      {/* LABEL TEXT */}
      {labelText !== undefined && (
        <InputLabel className={classes.labelRoot} shrink={true} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      )}

      {/* INPUT */}
      <Input
        autoFocus={autoFocus}
        classes={{
          input: input,
          disabled: classes.disabled,
        }}
        disableUnderline
        id={id}
        value={value}
        type={type}
        {...inputProps}
      />

      {/* CHECK ICON */}
      {success && <Check className={successClasses} />}

      {/* HELP TEXT */}
      {helpText && <FormHelperText id={`${id}-text`}>{helpText}</FormHelperText>}
    </FormControl>
  );
};

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  nbDecimal: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.bool]),
  transparent: PropTypes.bool,
  white: PropTypes.bool,
};

CustomInput.defaultProps = {
  autoFocus: false,
  type: 'text',
  nbDecimal: 0,
  formControlProps: {
    fullWidth: true,
  },
  id: uuidv4(),
};

export default withStyles(customInputStyle)(CustomInput);

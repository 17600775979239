import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  primaryColor,
  grayColor,
  redColor,
  yellowColor,
  purpleColor,
  pinkColor,
  dangerColor,
} from 'assets/styles/jss/main';

const CustomCheckbox = (props) => {
  const { color, error } = props;
  const style = {
    root: {
      color: error ? dangerColor : grayColor,
    },
    checked: {
      color: {
        primaryColor,
        secondaryColor: grayColor,
        redColor,
        yellowColor,
        purpleColor,
        pinkColor,
      }[`${color}Color`],
    },
  };

  const muiComponent = (props) => <Checkbox {...props} color='default' />;
  const NewComponent = withStyles(style)(muiComponent);

  return <FormControlLabel control={<NewComponent {...props} />} label={props.label} />;
};

CustomCheckbox.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'red', 'yellow', 'purple', 'pink']),
  label: PropTypes.node,
};

CustomCheckbox.defaultProps = {
  color: 'primary',
};

export default CustomCheckbox;

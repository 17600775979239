import React, { Component } from 'react';
import PropTypes from 'prop-types';
// creates a beautiful scrollbar

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import PagesHeader from 'components/Header/PagesHeader';

import authStyle from 'layouts/style/authStyle';
import getRoutes from 'routes/components';

class Pages extends Component {
  render() {
    const { classes, ...rest } = this.props;
    localStorage.clear();
    return (
      <div>
        <PagesHeader {...rest} color='whiteColor' />
        {/* eslint-disable-next-line react/no-string-refs */}
        <div className={classes.wrapper} ref='wrapper'>
          <div className={classes.fullPage}>
            {getRoutes('auth')}
            <div className={classes.fullPageBackground} />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(authStyle)(Pages);

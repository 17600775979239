import { drawerWidth, transition, containerFluid } from 'assets/styles/jss/main';

// import { background1, background2, background3 } from "../../data";

export default (theme) => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },

  mobileOnly: {
    '@media (max-width: 960px)': {
      display: 'none',
    },
  },

  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      overflowX: 'hidden',
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    height: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    position: 'relative',
    margin: '66px 0 40px',
    '@media screen and (min-width: 960px)': {
      margin: '40px 60px',
    },
    minHeight: 'calc(100vh - 152px)',
  },
  container: { ...containerFluid },
  map: {
    marginTop: '70px',
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
});

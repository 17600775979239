import { primaryColor, redColor, blackColor, grayColor, button, lightGrayColor } from 'assets/styles/jss/main';

const sweetAlertStyle = {
  sweetAlertContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: '4999',
  },
  button,
  modalHeader: {
    borderBottom: 'none',
    padding: '30px 10px 20px',
    minHeight: '16.43px',
    textAlign: 'left',
  },
  modalTitle: {
    margin: '0',
    textTransform: 'uppercase',
    marginLeft: 10,
    lineHeight: '1.42857143',
  },
  modalCloseContainer: {
    position: 'relative',
    marginTop: '-12px',
    width: 'auto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    float: 'right',
    borderRadius: '0 !important',
    '&:hover': {
      background: 'none !important',
      '& > span span': {
        fontWeight: 700,
      },
      '& > span div': {
        background: grayColor,
        boxShadow: `1px 1px 1px ${blackColor}`,
      },
    },
  },
  modalCloseButton: {
    position: 'relative',
    color: '#FFF',
    marginTop: '-12px',
    WebkitAppearance: 'none',
    padding: '0',
    cursor: 'pointer',
    background: 'rgba(17, 15, 36, 0.4)',
    border: '0',
    borderRadius: '3px',
    lineHeight: '1',
    boxShadow: '1px 1px 1px rgba(17, 15, 36, 0.4)',
  },
  modalCloseLabel: {
    transform: 'translateY(-5px)',
    paddingRight: '5px',
  },
  modalClose: {
    width: '40px',
    height: '40px',
  },
  modalBody: {
    paddingTop: '24px',
    paddingRight: '19px',
    paddingBottom: '16px',
    paddingLeft: '19px',
    position: 'relative',
  },
  modalBodyGrayBackground: {
    background: lightGrayColor,
  },
  modalFooter: {
    padding: '15px',
    textAlign: 'right',
    paddingTop: '0',
    margin: '0',
    '@media (max-width: 500px)': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  modalFooterCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  uploadInput: {
    position: 'absolute',
    left: 20,
    bottom: 14,
    '@media (max-width: 500px)': {
      position: 'initial',
      width: '100%',
      order: 2,
    },
  },
  instructionNoticeModal: {
    marginBottom: '25px',
  },
  imageNoticeModal: {
    maxWidth: '150px',
  },
  modalSmall: {
    width: '300px',
  },
  modalSmallBody: {
    paddingTop: '0',
  },
  modalSmallFooterFirstButton: {
    margin: '0',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: 'auto',
  },
  modalSmallFooterSecondButton: {
    marginBottom: '0',
    marginLeft: '5px',
  },
  cardTitle: {
    marginTop: '0',
    marginBottom: '3px',
    color: '#3C4858',
    fontSize: '18px',
  },
  paperFullWidth: {
    width: '100%',
    overflowY: 'visible',
    '@media screen and (max-width: 455.95px)': {
      width: '100vw',
      height: '100vh',
      maxHeight: 'unset',
      margin: 0,
      paddingTop: '40px',
      borderRadius: 0,
    },
  },
  paperWidthMd: {
    width: 695,
    maxWidth: '100%',
    overflowY: 'visible',
    '@media screen and (max-width: 455.95px)': {
      width: '100vw',
      height: '100vh',
      maxHeight: 'unset',
      margin: 0,
      paddingTop: '40px',
      borderRadius: 0,
    },
  },
  paperWidthSm: {
    width: 384,
    maxWidth: '100%',
    overflowY: 'visible',
    '@media screen and (max-width: 455.95px)': {
      width: '100vw',
      height: '100vh',
      maxHeight: 'unset',
      margin: 0,
      paddingTop: '40px',
      borderRadius: 0,
    },
  },
  paperWidthXs: {
    width: 384,
    maxWidth: '100%',
    overflowY: 'visible',
    '@media screen and (max-width: 455.95px)': {
      width: '100vw',
      height: '100vh',
      maxHeight: 'unset',
      margin: 0,
      paddingTop: '40px',
      borderRadius: 0,
    },
  },
  right: {
    textAlign: 'right',
  },
  left: {
    textAlign: 'left',
  },
  success: {
    backgroundColor: primaryColor,
    boxShadow:
      '0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)',
    '&:hover': {
      backgroundColor: primaryColor,
      boxShadow:
        '0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)',
    },
  },

  danger: {
    backgroundColor: redColor,
    boxShadow:
      '0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12)',
    '&:hover': {
      backgroundColor: redColor,
      boxShadow:
        '0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)',
    },
  },
  sweetAlert: {
    color: 'blue',
    // display: 'block',
    // marginTop: '-200px',
    // borderRadius: '0px'
  },
};

export default sweetAlertStyle;

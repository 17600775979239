import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import config from './config';

import 'regenerator-runtime/runtime';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import AuthLayout from 'layouts/AuthLayout';
import MainLayout from 'layouts/MainLayout';
import ContextProvider from './context/ContextProvider';

import 'assets/styles/css/main.css';

const hist = createBrowserHistory();

Sentry.init({
  environment: config.env,
  dsn: 'https://0ba946dbe1c24ad0a29e029783ba3ac2@o4504457039577088.ingest.sentry.io/4504752224206848',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <ContextProvider>
    <Router history={hist}>
      <Switch>
        <Route path='/auth' name='Authentification' component={AuthLayout} />
        <Route path='/' name='Utilisateurs' component={MainLayout} />
      </Switch>
    </Router>
  </ContextProvider>,
  document.getElementById('root'),
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';

import Card from 'components/Cards/Card';
import iconCardStyle from 'components/Cards/style/iconCardStyle';

function IconCard(props) {
  const {
    classes,
    title,
    content,
    iconColor,
    footer,
    footerAlign,
    footerBorder,
    plain,
    action,
    action2,
    offers,
    footerCenter,
    raised,
    absolutePositioning,
  } = props;

  const cardClasses = `${classes.card} ${cx({
    [classes.cardPlain]: plain,
  })}`;

  const footerClasses = cx({
    [classes.cardFooter]: true,
    [classes[footerAlign]]: true,
    [classes.cardFooterBorder]: footerBorder,
    [classes.footerCenter]: footerCenter,
  });

  const cardClear = cx({
    [classes.cardClear]: !action,
    [classes.cardClearBig]: action,
  });

  function bypassHeaderClass() {
    if (absolutePositioning) {
      return classes.simpleCard;
    } else {
      return cx({
        [classes.cardHeader]: !raised,
        [classes.cardHeaderBig]: raised,
      });
    }
  }

  const headerClasses = bypassHeaderClass();

  return (
    <Card className={cardClasses}>
      <CardHeader
        classes={{
          root: `${headerClasses} ${classes[`${iconColor}CardHeader`]}`,
          avatar: classes.cardAvatar,
          title: classes.cardTitle,
        }}
        avatar={<props.icon className={classes.cardIcon} />}
        title={title}
      />
      <div className={classes.actionButtonsContainer}>
        <div>{action}</div>
        {action2 && <div>{action2}</div>}
      </div>
      <CardContent className={classes.cardContent} style={offers && { paddingBottom: '0px' }}>
        {!raised && <div className={cardClear} />}
        {content}
      </CardContent>
      {footer && <CardActions className={footerClasses}>{footer}</CardActions>}
    </Card>
  );
}

IconCard.defaultProps = {
  iconColor: 'green',
  raised: false,
};

IconCard.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  iconColor: PropTypes.oneOf(['primary', 'red', 'yellow', 'purple', 'pink', 'black']),
  title: PropTypes.node,
  category: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node,
  footerBorder: PropTypes.bool,
  footerAlign: PropTypes.string,
  plain: PropTypes.bool,
  customCardContentClass: PropTypes.string,
};

export default withStyles(iconCardStyle)(IconCard);

// @material-ui/icons
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Settings from '@material-ui/icons/Settings';
import Email from '@material-ui/icons/Email';
import Work from '@material-ui/icons/Work';
import List from '@material-ui/icons/List';
import Group from '@material-ui/icons/Group';
import Help from '@material-ui/icons/Help';
import School from '@material-ui/icons/School';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonPin from '@material-ui/icons/PersonPin';
import Public from '@material-ui/icons/Public';
import Accessibility from '@material-ui/icons/Accessibility';

export const routes = [
  {
    user: true,
    path: '/modifier-profil',
    name: 'Modifier mon profil',
    icon: Settings,
  },
  {
    user: true,
    path: '/deconnexion',
    name: 'Déconnexion',
    icon: PowerSettingsNew,
  },
  {
    path: '/tableau-de-bord',
    name: 'Tableau de bord',
    icon: DashboardIcon,
  },
  {
    path: '/eleves',
    name: 'Élèves',
    icon: Group,
  },
  {
    path: '/professionnels',
    name: 'Professionnels',
    icon: Accessibility,
  },
  {
    path: '/entreprises',
    name: 'Entreprises',
    icon: Work,
  },
  {
    path: '/colleges',
    name: 'Profils collège',
    icon: School,
  },
  {
    path: '/rh',
    name: 'Ressources Humaines',
    icon: Public,
  },
  {
    path: '/offres',
    name: 'Offres',
    icon: List,
  },
  {
    path: '/candidatures',
    name: 'Candidatures',
    icon: PersonPin,
  },
  {
    path: '/messages',
    name: 'Messages',
    icon: Email,
  },
  {
    path: '/rep-liste',
    name: 'Liste Collèges Rep',
    icon: School,
  },
  {
    path: '/liste-colleges',
    name: 'Liste Collèges',
    icon: School,
  },
  {
    path: '/associations',
    name: 'Liste associations',
    icon: School,
  },
  {
    path: '/logs',
    name: 'Logs',
    icon: List,
  },
  {
    path: '/Faq',
    name: 'Faq',
    icon: Help,
  },
  {
    path: '/Quizz',
    name: 'Quizz',
    icon: QuestionAnswer,
  },
  {
    path: '/unusual-facts',
    name: 'Unusual facts',
    icon: School,
  },
  {
    path: '/Tutorials',
    name: 'Tutorial',
    icon: Help,
  },
  {
    path: '/Checklists',
    name: 'Checklists',
    icon: List,
  },
];

import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import config from '../../../config';
import { sortByStatus } from '../../../helpers/applications';
import { WrappedResponse } from '../../InternshipOffersPage/InternshipOffersPageService';


export interface Application {
    _id: string;
    // TODO: use enum status
    status: string;
    declined_reason?: string | Object;
    motivation_letter: string;
    motivation_letter_url: string;
    date:string;
    offer: {
        title: string;
        company: {
            name: string;
        }
        pro: {
            email: string;
        }
    }
}

const useStudentApplicationGet = (userID: string): WrappedResponse<Application[]> => {

    const [studentApplicationResponse, setStudentApplicationResponse] = useState<WrappedResponse<Application[]>>({
        status: 'loading'
    })

    const getStudentApplication = (): Promise<Application[]> => {
        return axios.get<Application[]>(
            `${config.API_URL}/api/applications/student/${userID}`)
            .then((response: AxiosResponse<Application[]>) => {
                return response.data;
            })
    }

    useEffect(() => {
        setStudentApplicationResponse({ status: 'loading' })
        getStudentApplication()
            .then(data => setStudentApplicationResponse({ status: 'success', payload: sortByStatus(data) }))
            .catch(error => setStudentApplicationResponse({ status: 'error', error }));

    }, [])

    return studentApplicationResponse;

}

export default useStudentApplicationGet;
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import applicationsStyle from '../../views/Student/style/applicationsStyle';

const CustomStatusLabel = ({
  status,
  availablePlace = undefined,
  isHiring = false,
  classes,
  declinedReason = undefined,
  studentId = undefined,
}) => {
  const getStyle = () => {
    switch (status) {
      case 'hiring':
        return classes.hiring;
      case 'declined':
        return classes.declined;
      case 'pending':
      default:
        return classes.pending;
    }
  };

  const getLabel = () => {
    switch (status) {
      case 'pending':
        return 'En attente de décision';
      case 'hiring':
        return 'Accepté·e';
      case 'declined':
        if (availablePlace === 0) {
          return 'Stage complet';
        }
        if (isHiring || declinedReason?.reason === 'Accepté ailleurs') {
          return 'Accepté·e ailleurs';
        }
        if (!!studentId && declinedReason?.user === studentId) {
          return "Déclinée par l'élève";
        }
        return 'Refusé·e';
      case 'cancel':
        return 'Annulée';
      default:
        return 'N/C';
    }
  };

  return <span className={getStyle()}>{getLabel()}</span>;
};

export default withStyles(applicationsStyle)(CustomStatusLabel);

import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import style from './style/style';

const CircularLoader = ({ classes, size, noWrapper }) => {
  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      {...(!noWrapper && { className: classes.containerWrapper })}>
      <Grid item>
        <CircularProgress
          size={size || 40}
          mode='indeterminate'
          classes={{
            root: classes.circlePrimary,
          }}
        />
      </Grid>
    </Grid>
  );
};

CircularLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.number,
  noWrapper: PropTypes.bool,
};

CircularLoader.defaultProps = {
  size: 60,
  noWrapper: false,
};

export default withStyles(style)(CircularLoader);

import { redColor, container, containerFluid } from '../../../assets/styles/jss/main';

export default {
  container,
  containerFluid,
  contentFullW: {
    padding: '5px 0',
    marginBottom: '10px',
    '@media screen and (min-width: 960px)': {
      padding: '12px 0 12px 120px',
      transform: 'translateX(-120px)',
      width: '100%',
      maxWidth: '100%',
      marginLeft: '0',
      '&::after': {
        position: 'absolute',
        display: 'block',
        content: "''",
        width: 'calc(100vw)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#FFF',
        zIndex: -1,
      },
    },
  },
  PageTitle: {
    padding: '15px',
    margin: 0,
    fontWeight: '300',
    fontSize: '28px',
    textTransform: 'uppercase',
  },
  offerTitle: {
    fontSize: '18px',
    fontWeight: '400',
    color: '#110f24',
  },
  actionsBtn: {
    marginLeft: '15px',
    width: '100%',
    '@media (min-width: 1836px)': {
      paddingLeft: '-15px',
    },
    '@media (max-width: 576px)': {
      paddingLeft: '0',
      alignItems: 'center',
      display: 'grid',
    },
  },
  subTitle: {
    maxWidth: '550px',
    marginLeft: '15px',
  },
  counterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
    },
  },
  counter: {
    position: 'relative',
    marginBottom: 10,
    '@media (max-width: 576px)': {
      margin: '5px 0 10px',
    },
  },
  counterBlock: {
    background: 'linear-gradient(#FFF 0%, #f2f2f2 100%)',
    height: '40px',
    width: '70px',
  },
  counterTop: {
    boxShadow: '0 10px 43px -3px rgba(0, 0, 0, 0.45)',
    borderRadius: '3px 3px 0 0',
  },
  counterBottom: {
    boxShadow:
      '0 2px 0px -1px #d8d8d8, 0 4px 0px -2px #c7c7c7, 0 6px 0px -3px #d8d8d8, 0 8px 0px -4px #c6c6c6, 0 10px 0px -5px #d6d6d6, 0 12px 0px -6px #c9c9c9, 0 14px 0px -7px #d8d8d8, 0 14px 23px -9px rgba(0, 0, 0, 0.8)',
    borderRadius: '0 0 3px 3px',
  },
  counterText: {
    //...customFont,
    fontSize: 24,
    fontWeight: 'bold',
    margin: '0 10px',
    color: redColor,
  },
  counterNumber: {
    //...customFont,
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 40,
    fontWeight: 'bold',
    color: redColor,
  },
  noData: {
    //...customFont,
    fontSize: 18,
    margin: '150px 0',
    textAlign: 'center',
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      WebkitFlexDirection: 'column',
    },
  },
  displayFlex2: {
    display: 'flex',
    alignItems: 'center',
  },
  companyName: {
    color: '#6ac0b6',
    display: 'flex',
    alignItems: 'center',
  },
  titleCompany: {
    marginTop: '15px',
    padding: '0 0 0 10px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  hiring: {
    width: '100%',
    backgroundColor: '#3ca455',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '100px',
    color: '#ffffff',
    textAlign: 'center',
    alignSelf: 'center',
    maxWidth: '250px',
    padding: '2px 10px',
    justifySelf: 'center',
  },
  declined: {
    width: '100%',
    backgroundColor: '#e67337',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '100px',
    textAlign: 'center',
    color: '#ffffff',
    alignSelf: 'center',
    maxWidth: '250px',
    padding: '2px 10px',
    justifySelf: 'center',
  },
  pending: {
    width: '100%',
    backgroundColor: '#ececee',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '100px',
    color: '#110f24',
    alignSelf: 'center',
    maxWidth: '250px',
    padding: '2px 10px',
    justifySelf: 'center',
  },
  congratulations: {
    background: '#3ca455',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '13px 16px',
    borderRadius: '4px',
    alignItems: 'center',
    fontWeight: 'bold',
    margin: '15px',
    '@media (max-width: 959px)': {
      marginTop: '80px',
    },
  },
  congratulations_close: {
    '&:hover': {
      cursor: 'pointer',
    },
    lineHeight: '11px',
  },
  offers_similar: {
    '@media screen and (max-width: 959.95px)': {
      transform: 'translateX(-22px)',
      width: 'calc(100% + 44px)',
    },
    '@media screen and (min-width: 960px)': {
      '& > div:nth-child(odd)': {
        paddingRight: '15px !important',
      },
    },
  },
  offers_similar_button: {
    margin: '.6rem 0',
    '@media screen and (max-width: 959.95px)': {
      margin: '.6rem auto',
      maxWidth: 'fit-content',
    },
  },
};

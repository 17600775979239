import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import config from '../../../config';
import { WrappedResponse } from '../../InternshipOffersPage/InternshipOffersPageService';


export interface Student {
    _id: string;
    email: string,
    account: {
        first_name: string;
        last_name: string;
        phone: string;
        class?: string;
        rapport?: string;
        convention?: string;
    }
}

const useStudentGet = (userID: string): WrappedResponse<Student> => {

    const [student, setStudent] = useState<WrappedResponse<Student>>({
        status: 'loading'
    })

    const getStudent = (): Promise<Student> => {
        return axios.get<Student>(
            `${config.API_URL}/api/users/${userID}`)
            .then((response: AxiosResponse<Student>) => {
                return response.data;
            })
    }

    useEffect(() => {
        setStudent({ status: 'loading' })
        getStudent()
            .then(data => setStudent({ status: 'success', payload: data }))
            .catch(error => setStudent({ status: 'error', error }));

    }, [])

    return student;

}

export default useStudentGet;
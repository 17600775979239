import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { matchSorter } from 'match-sorter';

const ReactTableWrapper = ({
  data,
  columns,
  children,
  filterable,
  filters,
  orders,
  page,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
  handleOrderChange,
  handleFilterChange,
  bodyCenter,
  noDataText,
  showPagination,
  loading,
  sorted,
}) => {
  return (
    <ReactTable
      data={data}
      columns={columns}
      filterable={filterable}
      sorted={sorted}
      {...(filters && {
        defaultFiltered: Object.entries(filters).map((f) => ({
          id: f[0],
          value: f[1],
        })),
      })}
      {...(orders && {
        defaultSorted: Object.entries(orders).map((f) => ({
          id: f[0],
          desc: f[1],
        })),
      })}
      resizable={true}
      loading={loading}
      // filtering
      defaultFilterMethod={(filter, row) =>
        matchSorter([row[filter.id]], filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
        }).length !== 0
      }
      // pagination
      {...(page && handlePageChange && { page })}
      defaultPageSize={10}
      pageSize={pageSize}
      pageSizeOptions={[10, 20, 30, 50, 100, 200]}
      showPaginationTop={showPagination}
      showPaginationBottom={false}
      // text
      previousText='← Précédent'
      nextText='Suivant →'
      loadingText='Chargement...'
      noDataText={noDataText || 'Pas de données'}
      pageText='Page'
      ofText='sur'
      rowsText='lignes'
      {...(handlePageChange && {
        onPageChange: (pageIndex) => handlePageChange(pageIndex),
      })}
      {...(handleOrderChange && {
        onSortedChange: (newOrder) => handleOrderChange(newOrder),
      })}
      {...(handleFilterChange && {
        onFilteredChange: (filtered) => {
          handlePageChange(0);
          handleFilterChange(filtered);
        },
      })}
      {...(handlePageSizeChange && {
        onPageSizeChange: (pageSize, pageIndex) => {
          handlePageChange(pageIndex);
          handlePageSizeChange(pageSize);
        },
      })}
      // style
      className={`-striped -highlight ${bodyCenter && '-body-center'}`}>
      {children}
    </ReactTable>
  );
};

ReactTableWrapper.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  filterable: PropTypes.bool,
  bodyCenter: PropTypes.bool,
  children: PropTypes.func,
};

ReactTableWrapper.defaultProps = {
  showPagination: true,
  perPage: 10,
};

export default ReactTableWrapper;

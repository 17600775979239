// ##############################
// // // Pages Header styles
// #############################

import {
  container,
  defaultFont,
  whiteColor,
  blackColor,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  boxShadow,
  drawerWidth,
  transition,
} from 'assets/styles/jss/main';

const pagesHeaderStyle = () => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'absolute',
    width: '100%',
    paddingTop: '10px',
    zIndex: '5',
    color: '#555555',
    border: '0',
    borderRadius: '3px',
    padding: '5px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  },
  container: {
    ...container,
    minHeight: 60,
    height: 60,
    paddingLeft: 0,
    paddingRight: 0,
    '@media (min-width: 768px)': {},
    '@media (min-width: 992px)': {},
    '@media (min-width: 1200px)': {},
  },
  flex: {
    flex: 1,
  },
  logoWrapper: {
    margin: 0,
    '@media screen and (max-width: 959.95px)': {
      maxWidth: '100%',
      justifyContent: 'center',
    },
    '@media screen and (min-width: 960px)': {
      width: 'fit-content',
      paddingLeft: 5,
      justifyContent: 'flex-start',
    },
  },
  logo: {
    marginLeft: '9vw',
    height: '60px',
  },
  appResponsive: {
    top: '8px',
  },
  primary: {
    backgroundColor: primaryColor,
    color: whiteColor,
  },
  info: {
    backgroundColor: infoColor,
    color: whiteColor,
  },
  success: {
    backgroundColor: successColor,
    color: whiteColor,
  },
  warning: {
    backgroundColor: warningColor,
    color: '#FFFFFF',
  },
  danger: {
    backgroundColor: dangerColor,
    color: '#FFFFFF',
  },
  whiteColor: {
    backgroundColor: whiteColor,
    color: blackColor,
  },
  blackColor: {
    backgroundColor: blackColor,
    color: whiteColor,
    ...defaultBoxShadow,
  },
  list: {
    ...defaultFont,
    display: 'flex',
    '& li': {
      width: 'fit-content',
    },
    '@media screen and (max-width: 959.95px)': {
      display: 'none',
    },
  },
  listMobile: {
    ...defaultFont,
    fontSize: '14px',
    margin: 0,
    marginRight: '-15px',
    paddingLeft: '0',
    listStyle: 'none',
    color: `${blackColor} !important`,
    paddingTop: '0',
    paddingBottom: '0',
  },
  navLink: {
    color: blackColor,
    fontWeight: '500',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    '&:hover,&:focus': {
      color: primaryColor,
    },
  },
  listItemIcon: {
    color: 'inherit',
    minWidth: 'auto',
    marginRight: '5px',
  },
  listItemText: {
    display: 'inline-block',
    fontSize: '14px',
  },
  navLinkActive: {
    color: primaryColor,
  },
  drawerPaper: {
    backgroundColor: whiteColor,
    border: 'none',
    bottom: '0',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    ...boxShadow,
    width: drawerWidth,
    ...boxShadow,
    position: 'fixed',
    display: 'block',
    top: '0',
    height: '100vh',
    visibility: 'visible',
    overflowY: 'visible',
    borderTop: 'none',
    textAlign: 'left',
    padding: '0',
    ...transition,
  },
  sidebarButton: {
    color: '#FFFFFF',
  },
  burgerMenu: {
    position: 'absolute',
    top: 10,
    left: 8,
    display: 'none',
    textAlign: 'center',
    color: blackColor,
    margin: 0,
    '& svg': {
      width: 24,
      height: 24,
    },
    '& span': {
      color: blackColor,
      fontSize: 12,
      lineHeight: 0.123,
    },
    '@media screen and (max-width: 960px)': {
      display: 'block',
    },
  },
  needHelp: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    cursor: 'pointer',
    '& .text': {
      display: 'block',
    },
    '& .icon': {
      display: 'none',
    },
    '@media screen and (max-width: 1269px)': {
      '& .text': {
        display: 'none',
      },
      '& .icon': {
        display: 'block',
      },
    },
  },
});

export default pagesHeaderStyle;

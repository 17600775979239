import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import CardWrapper from '@material-ui/core/Card';
import cx from 'classnames';
import style from './style/CardStyle';

//className
const Card = ({ classes, className, children, isSelected, offers, ...rest }) => {
  const cardClasses = cx({
    [classes.card]: true,
    [classes.selected]: isSelected,
    [classes.offers]: offers,
  });

  return (
    <CardWrapper className={`${cardClasses} ${className}`} classes={{ root: classes.root }} {...rest}>
      {children}
    </CardWrapper>
  );
};

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
  rest: PropTypes.object,
};

export default withStyles(style)(Card);

// eslint-disable-next-line
import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = __ENV__.REACT_APP_NODE_ENV || 'developement';

const config = {
  ENV: env,
  API_URL: 'http://localhost:3100',
  FACEBOOK_ID: '180767432584646',
  GOOGLE_ID: '631541600138-emaqls0shvf1du3sc89gpj5ull8aaqie.apps.googleusercontent.com',
  GOOGLE_MAP: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBI64nFnz3B5pGDya4hlvL3b8kB-4xM8Mw',
  FIREBASE: {
    apiKey: 'AIzaSyCpRB6w89PiiJQRfD2w-6F7Eq0pWEUJ7bk',
    authDomain: 'vvmt-1079.firebaseapp.com',
    databaseURL: 'https://vvmt-1079.firebaseio.com',
    projectId: 'vvmt-1079',
    storageBucket: 'vvmt-1079.appspot.com',
    messagingSenderId: '955603247737',
    appId: '1:955603247737:web:1ae3455b9f11d107f2aaa8',
    measurementId: 'G-B96GR81Q8Y',
  },
};

if (env === 'staging') {
  config.GOOGLE_MAP = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBI64nFnz3B5pGDya4hlvL3b8kB-4xM8Mw';
  config.API_URL = '/service';
}

if (env === 'production') {
  config.GOOGLE_ID = '955603247737-kg1rlrchboasav0femf4c2ffjcqhbl31.apps.googleusercontent.com';
  config.GOOGLE_MAP = '';
  config.API_URL = 'https://api.viensvoirmontaf.fr';
}

export default config;

const extendedTablesStyle = {
  // React Table
  hired: {
    color: 'green',
  },
  notHired: {
    color: 'red',
  },

  cursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    fontWeight: 500,
    fontSize: 16,
    padding: '6px 12px 6px 6px',
    margin: '0px 0px 0px 25px',
    borderRadius: '4px',
    lineHeight: 1.5,
    backgroundColor: '#6ac0b6',
    borderColor: '#6ac0b6',
    '&:hover': {
      backgroundColor: '#49aea2',
      borderColor: '#49aea2',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#6ac0b6',
      borderColor: '#6ac0b6',
    },
  },
};

export default { ...extendedTablesStyle };

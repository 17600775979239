import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import cx from 'classnames';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import Helmet from 'components/Wrapper/Helmet';
import config from 'config';
import { Context } from 'context/Context';
import mainStyle from 'layouts/style/mainStyle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import getRoutes from 'routes/components';
import { routes as administratorRoutes } from 'routes/menu/administrator';
import { routes as associationRoutes } from 'routes/menu/association';
import { routes as collegeRoutes } from 'routes/menu/college';
import { routes as guestRoutes } from 'routes/menu/guest';
import { routes as hrRoutes } from 'routes/menu/hr';
import { routes as inactiveStudentRoutes } from 'routes/menu/inactiveStudent';
import { routes as proRoutes } from 'routes/menu/pro';
import { routes as studentRoutes } from 'routes/menu/student';
import { initializeFirebaseApp } from './../firebase';
import ProfileCollegeModal from 'components/ProfileCollegeModal/ProfileCollegeModal';

import { InstallPWA } from 'components/InstallPWA/InstallPWA';

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundNumber: 1,
      mobileOpen: false,
      miniActive: false,
      routes: null,
    };
  }

  componentDidMount() {
    this.setState({ backgroundNumber: Math.floor(Math.random() * 3 + 1) });
    this.getAccount(this.props.context).then((account) => {
      this.determineRoutes(account);
      this.initFirebase(account);
    });
  }

  async initFirebase(account) {
    if (account && account.type === 'pro') {
      try {
        const firebaseToken = await initializeFirebaseApp();
        // Update user si le token a changé ou si il a été changé
        if (!account.token_web_device || account.token_web_device != firebaseToken) {
          await this.updateProWithToken(firebaseToken);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async updateProWithToken(firebaseToken) {
    axios
      .put(`${config.API_URL}/api/users/${this.props.context.state.user._id}`, {
        'account.token_web_device': firebaseToken,
      })
      .catch((e) => {
        console.error(e);
        const message = {
          type: 'danger',
          content: `Erreur lors de la mise à jour du jeton de firebase pour ${this.props.context.state.user.email}, error => ${e}`,
        };
        axios.post(`${config.API_URL}/api/logs`, message);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.context.state.user !== undefined && this.props.context.state.user !== undefined) {
      if (prevProps.context.state.user.account.type !== this.props.context.state.user.account.type) {
        this.getAccount(this.props.context).then((account) => {
          this.determineRoutes(account);
        });
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleDrawerClose = () => {
    this.setState({ mobileOpen: false });
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  getAccount = async (context) => {
    const { user } = context.state;

    if (user && user.account && user.account.type) {
      this.setAxiosHeader(context.state.user.token);

      if (user.impersonated || (user.account.type === 'pro' && user.email.split('@')[1] === 'bnpparibas.com')) {
        user.account.is_active = true;
      }
      return context.state.user.account;
    }

    const localToken = localStorage.getItem('token');
    const localImpersonating = !!JSON.parse(localStorage.getItem('isImpersonating'));

    if (localToken && localToken !== 'undefined') {
      const token = JSON.parse(localToken);

      return await axios
        .post(`${config.API_URL}/public/auth/verify_token`, { token })
        .then((res) => {
          context.updateUser({ ...res.data.user, impersonated: localImpersonating });
          this.setAxiosHeader(res.data.user.token);
          return res.data.user.account;
        })
        .catch(() => this.props.history.push('/auth/inscription'));
    }
    return null;
  };

  setAxiosHeader = (token) => {
    // axios.defaults.baseURL = config.API_URL;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  updateLastConnection = () => {
    const {
      _id,
      account: { type },
    } = this.props.context.state.user;

    const typeToSaveLastconnection = ['pro', 'rh', 'student', 'college'];
    if (!typeToSaveLastconnection.includes(type)) return;

    const isAdminImpersonification = localStorage.getItem('admin') !== null;
    if (isAdminImpersonification) return;

    const body = { 'account.last_connection': Date.now() };
    axios.put(`${config.API_URL}/api/users/${_id}`, body);
  };

  determineRoutes = (account) => {
    if (!account) {
      if (window.location.search && window.location.search === '?source=stage3e') {
        return this.props.history.push('/auth/inscription/stage3e');
      } else {
        return this.props.history.push('/auth/inscription');
      }
    }

    if (account.type === 'student' && !account.is_active) {
      this.setState({ routes: inactiveStudentRoutes });
      return;
    }

    switch (account.type) {
      case 'student':
        this.setState({ routes: studentRoutes });
        this.updateLastConnection();
        break;
      case 'college':
        this.setState({ routes: collegeRoutes });
        this.updateLastConnection();
        break;
      case 'association':
        this.setState({ routes: associationRoutes });
        break;
      case 'pro':
        this.setState({ routes: proRoutes });
        this.updateLastConnection();
        break;
      case 'hr':
        this.setState({ routes: hrRoutes });
        this.updateLastConnection();
        break;
      case 'guest':
        this.setState({ routes: guestRoutes });
        break;
      case 'administrator':
        this.setState({ routes: administratorRoutes });
        break;
      default:
        this.props.history.push('/auth/inscription');
        break;
    }
  };

  render() {
    const { classes, context, ...rest } = this.props;
    const { routes, backgroundNumber } = this.state;

    const newHeader = context.state.user && ['student', 'pro', 'hr'].includes(context.state.user.account.type);

    const mainPanel = cx({
      [classes.mainPanel]: true,
      [classes[`background${backgroundNumber}`]]: true,
      //[classes.mainPanelSidebarMini]: this.state.miniActive
    });

    return (
      routes && (
        <div className={classes.wrapper}>
          {this.props.context.state.user.account.type === 'college' && !localStorage.getItem('admin') && (
            <ProfileCollegeModal user={this.props.context.state.user} />
          )}
          <InstallPWA />
          <Helmet />
          <Sidebar
            context={this.props.context}
            routes={routes}
            handleDrawerToggle={this.handleDrawerToggle}
            handleDrawerClose={this.handleDrawerClose}
            open={this.state.mobileOpen}
            color='white'
            bgColor='white'
            miniActive={this.state.miniActive}
            newHeader={newHeader}
            {...rest}
          />

          <div className={mainPanel}>
            <Header
              className={classes.mobileOnly}
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
              handleDrawerToggle={this.handleDrawerToggle}
              newHeader={newHeader}
              {...rest}
            />
            <div className={classes.content}>{getRoutes(context.state.user.account.type)}</div>
          </div>
        </div>
      )
    );
  }
}

MainLayout.propTypes = {
  context: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(mainStyle)((props) => (
  <Context.Consumer>{(context) => <MainLayout {...props} context={context} />}</Context.Consumer>
));

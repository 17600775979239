import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Context } from 'context/Context';
import config from 'config';
import style from './style/style';
import { Link } from 'react-router-dom';
// icons
import SentimentSatisfied from '@material-ui/icons/SentimentSatisfied';
// material-ui
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import ReactTableWrapper from 'components/Table/ReactTableWrapper';
import LinearLoader from 'components/Loader/LinearLoader';
import IconCard from 'components/Cards/IconCard';
import Requests from 'hoc/Requests';
import GridContainer from 'components/Grid/GridContainer';
import ItemGrid from 'components/Grid/ItemGrid';
import ErrorBoundaries from 'components/Wrapper/ErrorBoundaries';
import SweetModal from 'components/SweetModal/SweetModal';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Grid } from '@material-ui/core';

class Students extends Component {
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    const { _id } = this.props.context.state.user;
    this.props.fetch({
      url: `${config.API_URL}/api/users/association/${_id}/students`,
    });
  }

  renderIconCard = (content) => {
    return <IconCard icon={SentimentSatisfied} iconColor={'red'} title={'Mes jeunes'} content={content} />;
  };

  toggleApplicationsModal = () => {
    this.setState((prevState) => ({
      ModalApplications: '',
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  translateStatus = (status) => {
    const { classes } = this.props;
    switch (status) {
      case 'pending':
        return 'En attente';
      case 'hiring':
        return <span className={classes.successColor}>Accepté</span>;
      case 'declined':
        return <span className={classes.dangerColor}>Refusée / Retirée</span>;
      default:
        return 'N.C.';
    }
  };

  applicationModalInputs = () => {
    const { dataModal } = this.state;
    return dataModal.map((application, index) => {
      return (
        <div key={index}>
          <div className='modal-applications-details-content'>
            <div>
              <span className='bold'>Entreprise : </span> {application.offer.company.name}
            </div>
            <div>
              <span className='bold'>Addresse : </span> {application.offer.address}
            </div>
            <div>
              <span className='bold'>Status : </span>
              {this.translateStatus(application.status)}
            </div>
            <div>
              <span className='bold'>Date : </span>
              {format(new Date(application.date), 'dd/MM/yyyy')}
            </div>
            <div>
              <span className='bold'>Lettre de motivation </span>
              <div className='motivation_letter'>{application.motivation_letter}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  renderTable = () => {
    const { data, loading, classes } = this.props;

    const columns = [
      {
        Header: 'Prénom',
        accessor: 'account.first_name', // String-based value accessors!
      },
      {
        Header: 'Nom',
        accessor: 'account.last_name',
      },
      {
        Header: 'Candidature',
        accessor: 'application.number',
        Cell: (row) => <div>{row.original.application.number}</div>,
      },
      {
        Header: 'Embauché',
        accessor: 'application.statut',
        Cell: (row) => (
          <div className={row.original.application.statut === 'oui' ? classes.hired : classes.notHired}>
            {row.value}
          </div>
        ),
      },
      {
        Header: 'Inscription',
        accessor: 'account.first_connection',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy'),
      },
      {
        Header: '',
        Cell: (row) => (
          <Grid container>
            <div>
              {row.original.application.number > 0 && (
                <Link to={`/jeunes/${row.original._id}/candidatures`} className={classes.button}>
                  <NavigateNextIcon style={{ color: '#FFFFFF' }} fontSize='small' />
                  <div style={{ color: '#FFFFFF' }}>Voir les détails</div>
                </Link>
              )}
            </div>
          </Grid>
        ),
      },
    ];

    // si le collège n'a pas encore créé de classe,
    // on lui affiche le tutoriel
    if (data.length <= 0 && !loading) {
      //return "Vous n'avez pas encore d'élèves"
      return this.renderIconCard(
        <ReactTableWrapper data={[]} columns={columns} noDataText="Vous n'avez pas encore d'élèves !" />,
      );

      //return this.renderIconCard("vous n'avez pas encore d'élèves")
    } else if (data.length) {
      return this.renderIconCard(<ReactTableWrapper data={data} bodyCenter columns={columns} />);
    } else {
      return this.renderIconCard(<LinearLoader />);
    }
  };

  render() {
    const { isModalOpen } = this.state;

    const ApplicationsModal = isModalOpen && (
      <SweetModal
        title={`Details des candidatures`}
        isOpen={isModalOpen}
        inputs={this.applicationModalInputs()}
        toggleModal={this.toggleApplicationsModal}
        submit={this.toggleApplicationsModal}
        submitText='Vu'
        noCancel
      />
    );
    return (
      <ErrorBoundaries>
        <Fragment>
          {ApplicationsModal}
          <GridContainer key='container'>
            <ItemGrid xs={12}>{this.renderTable()}</ItemGrid>
          </GridContainer>
        </Fragment>
      </ErrorBoundaries>
    );
  }
}

Students.propTypes = {
  context: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

const WrappedComponent = Requests(Students);

export default withStyles(style)((props) => (
  <Context.Consumer>{(context) => <WrappedComponent {...props} context={context} />}</Context.Consumer>
));

import { primaryColor } from '../../../assets/styles/jss/main';

export default {
  root: {
    overflow: 'visible',
    boxShadow: 'none',
  },
  elevation: {
    boxShadow: 'none',
  },
  card: {
    padding: '10px',
    borderRadius: '5px',
  },
  cardContent: {
    cursor: 'pointer',
    position: 'relative',
    paddingBottom: '0px',
    height: '100%',
    // textTransform: "uppercase"
  },
  selected: {
    border: `0.5px solid ${primaryColor}`,
  },
  offers: {
    height: '100%',
  },
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import style from './style/style';
import Button from 'components/CustomButtons/Button';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';

import withStyles from '@material-ui/core/styles/withStyles';

import Close from '@material-ui/icons/Close';

// eslint-disable-next-line react/display-name
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction='up' ref={ref} {...props} />;
});

class SweetModal extends Component {
  applyClassTitle = (element, className) =>
    element && element.props && element.props.children
      ? React.cloneElement(element, {
          children: React.Children.map(element.props.children, (child) =>
            child && child.type === 'h4'
              ? React.cloneElement(child, { className })
              : this.applyClassTitle(child, className),
          ),
        })
      : element;

  render() {
    const {
      title,
      isOpen,
      uploadInput,
      inputs,
      classes,
      submit,
      noSubmit,
      noCancel,
      noClose,
      closeAlt,
      cancelText,
      submitText,
      submitFullWidth,
      submitDisabled,
      toggleModal,
      fullScreen,
      fullWidth,
      key,
      maxWidth,
      withBackground,
      customFooter,
      className,
      contentStyle,
    } = this.props;

    const bodyClass = cx({
      [classes.modalBody]: true,
      [classes.modalBodyGrayBackground]: withBackground,
    });

    return (
      <Dialog
        key={key}
        classes={{
          root: classes.root,
          paper: className,
          paperWidthXs: classes.paperWidthXs,
          paperWidthSm: classes.paperWidthSm,
          paperWidthMd: classes.paperWidthMd,
          paperFullWidth: classes.paperFullWidth,
        }}
        open={isOpen}
        fullScreen={fullScreen || false}
        fullWidth={fullWidth || false}
        maxWidth={fullWidth ? false : maxWidth}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleModal}
        aria-labelledby='classic-modal-slide-title'
        aria-describedby='classic-modal-slide-description'>
        <DialogTitle id='classic-modal-slide-title' disableTypography className={classes.modalHeader}>
          {!noClose && (
            <IconButton
              className={classes.modalCloseContainer}
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={toggleModal}
              edge={'start'}>
              <span className={classes.modalCloseLabel}>Fermer</span>
              <div className={classes.modalCloseButton}>
                <Close className={classes.modalClose} />
              </div>
            </IconButton>
          )}
          {noClose && closeAlt && closeAlt}
          {React.isValidElement(title) ? (
            this.applyClassTitle(title, classes.modalTitle)
          ) : (
            <h4 className={classes.modalTitle}>{title}</h4>
          )}
        </DialogTitle>
        <DialogContent id='classic-modal-slide-description' className={bodyClass} style={contentStyle}>
          {inputs}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {uploadInput !== undefined && <div className={classes.uploadInput}>{uploadInput}</div>}

          {!noCancel && (
            <Button onClick={toggleModal} fullWidth={submitFullWidth} color='lightGray'>
              {cancelText || 'Annuler'}
            </Button>
          )}
          {!noSubmit && (
            <Button color='primary' fullWidth={submitFullWidth} onClick={submit} disabled={submitDisabled}>
              {submitText || 'Valider'}
            </Button>
          )}

          {noSubmit && noCancel && customFooter !== undefined && customFooter}
        </DialogActions>
      </Dialog>
    );
  }
}

SweetModal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  inputs: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  submit: PropTypes.func,
  noSubmit: PropTypes.bool,
  cancelText: PropTypes.string,
  uploadInput: PropTypes.node,
  key: PropTypes.string,
  submitText: PropTypes.string,
  submitFullWidth: PropTypes.bool,
  noCancel: PropTypes.bool,
  noClose: PropTypes.bool,
  closeAlt: PropTypes.node,
  withBackground: PropTypes.bool,
  customFooter: PropTypes.node,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', null]),
  submitDisabled: PropTypes.bool,
};

SweetModal.defaultProps = {
  submitFullWidth: false,
  withBackground: false,
  maxWidth: 'sm',
  submitDisabled: false,
};

export default withStyles(style)(SweetModal);

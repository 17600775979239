function compare(a, b) {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}

const sortByStatus = (applications) => {
  // const order = ["hiring", "pending", "declined", "cancel"];

  const status_order = {
    hiring: 0,
    pending: 1,
    declined: 2,
    cancel: 3,
  };

  return applications.sort((a, b) => {
    let index_result = compare(status_order[a.status], status_order[b.status]);
    if (index_result === 0) {
      return compare(a.status, b.status);
    }
    return index_result;
  });
};

exports.sortByStatus = sortByStatus;

// @material-ui/icons
import Settings from '@material-ui/icons/Settings';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Comment from '@material-ui/icons/Comment';
import Add from '@material-ui/icons/Add';
import Description from '@material-ui/icons/Description';

export const routes = [
  {
    user: true,
    path: '/modifier-profil',
    name: 'Modifier mon profil',
    icon: Settings,
  },
  {
    user: true,
    path: '/deconnexion',
    name: 'Déconnexion',
    icon: PowerSettingsNew,
  },
  {
    path: '/annonces',
    name: 'Mes offres',
    icon: Description,
  },
  {
    path: '/nouvelle-annonce',
    name: 'Créer une offre',
    icon: Add,
  },
  {
    path: '/messages',
    name: 'Mes messages',
    icon: Comment,
  },
  {
    path: '/charte',
    name: 'La charte VVMT',
    icon: Description,
  },
];

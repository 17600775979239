import React, { Component } from 'react';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import style from './style/chipStyle';

class CustomChip extends Component {
  render() {
    const { classes, className, bold, sep, small, color, children, ...restProps } = this.props;

    const customClass = cx({
      [classes.bold]: bold,
      [classes.sep]: sep,
      [classes.small]: small,
    });

    return (
      <Chip
        color='primary'
        label={children}
        classes={{
          colorPrimary: classes[`${color}Color`],
        }}
        className={[customClass, className].filter((_) => _).join(' ')}
        {...restProps}
      />
    );
  }
}

CustomChip.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  bold: PropTypes.bool,
  sep: PropTypes.bool,
  small: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'red', 'yellow', 'purple', 'orange', 'pink', 'lightGray']),
};

CustomChip.defaultProps = {
  color: 'lightGray',
  className: '',
};

export default withStyles(style)(CustomChip);

// ##############################
// // // SearchBar styles
// #############################

import { primaryColor, primaryBoxShadow, defaultFont } from '../../../assets/styles/jss/main';

const searchBarStyle = () => ({
  container: {
    position: 'relative',
  },
  searchInputContainer: {
    position: 'relative',
  },
  clearButton: {
    position: 'absolute',
    right: '30px',
    transform: 'translateY(-50%)',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    fontWeight: '600',
    color: '#999',
    '&:hover,&:focus': {
      cursor: 'pointer',
    },
  },
  buttonWithoutLabel: {
    top: '24px',
  },
  buttonWithLabel: {
    top: '55px',
  },
  autocompleteContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '55px',
    borderRadius: '3px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    zIndex: '1000',
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
  },
  suggestionItem: {
    ...defaultFont,
    fontSize: '13px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '2px',
    position: 'relative',
    transition: 'all 150ms linear',
    display: 'block',
    clear: 'both',
    fontWeight: '400',
    height: 'fit-content',
    color: '#333',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: primaryColor,
      color: '#FFFFFF',
      ...primaryBoxShadow,
    },
  },
  suggestionItemActive: {
    backgroundColor: '#fafafa',
  },
  dropdownFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '4px',
  },
  dropdownFooterImage: {
    display: 'inline-block',
    width: '100px',
  },
  errorMessage: {
    color: 'red',
  },
});

export default searchBarStyle;

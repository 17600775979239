// @material-ui/icons
import PersonAdd from '@material-ui/icons/PersonAdd';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockOpen from '@material-ui/icons/LockOpen';

export const routes = [
  {
    path: '/auth/inscription/',
    name: "S'inscrire",
    short: "S'inscrire",
    mini: 'IN',
    icon: PersonAdd,
  },
  {
    path: '/auth/connexion',
    name: 'Se connecter',
    short: 'Se connecter',
    mini: 'CO',
    icon: AccountCircle,
  },
  {
    path: '/auth/mot-de-passe',
    name: 'Mot de passe oublié ?',
    short: 'Mdp oublié ?',
    mini: 'MDP',
    icon: LockOpen,
  },
  {
    path: '/auth/',
    pathTo: '/auth/connexion',
    name: "S'inscrire",
  },
];

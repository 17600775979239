import {
  card,
  cardHeader,
  cardHeaderBig,
  primaryCardHeader,
  redCardHeader,
  yellowCardHeader,
  purpleCardHeader,
  pinkCardHeader,
  //customFont
} from 'assets/styles/jss/main';

const iconCardStyle = {
  card,
  cardHeaderBig,
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none',
  },
  simpleCard: {
    width: '264px',
    maxHeight: '62px',
  },
  cardHeader: {
    ...cardHeader,
    float: 'left',
  },
  actionButtonsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '-.3rem',
    '&:first-of-type': {
      marginTop: '-45px',
    },
    '@media screen and (max-width: 576px)': {
      justifyContent: 'center',
      '& p': {
        width: '100%',
        textAlign: 'center',
      },
      '&:first-of-type': {
        marginTop: 25,
      },
    },
  },
  primaryCardHeader,
  redCardHeader,
  yellowCardHeader,
  purpleCardHeader,
  pinkCardHeader,
  cardContent: {
    //padding: '15px 20px',
    padding: '5px 20px',
    position: 'relative',
  },
  cardAvatar: {
    margin: '0px',
  },
  cardIcon: {
    paddingTop: '3px',
    paddingLeft: '4px',
    paddingRight: '5px',
    color: '#FFFFFF',
    width: '33px',
    height: '27px',
  },
  cardTitle: {
    //...customFont,
    margin: '0 5px 0 10px',
    fontSize: '16px',
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
  },
  cardClear: {
    height: 45, // 18
  },
  cardClearBig: {
    height: '50px',
    '@media (max-width: 576px)': {
      height: '85px',
    },
  },
  cardFooter: {
    display: 'block',
    margin: '0 20px 10px',
    paddingTop: '10px',
  },
  cardFooterBorder: {
    borderTop: '1px solid #eeeeee',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  center: {
    textAlign: 'center',
  },
  offers: {
    paddingBottom: '0px',
  },
  footerCenter: {
    textAlign: 'center',
  },
};

export default iconCardStyle;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// eslint-disable-next-line react/display-name
const Router = (routes) => () => {
  return (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.pathTo) {
          return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
        }
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  );
};

export default Router;

import React, { useState } from 'react';

import useIsIOS from '../../hooks/useIsIOS';
import share from '../../assets/images/apple-share.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export const InstallPWA = () => {
  const { prompt } = useIsIOS();
  const [modalOpen, setModalOpen] = useState(true);

  return prompt ? (
    <Dialog
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      scroll='paper'
      aria-labelledby='install-pwa-title'
      aria-describedby='install-pwa-description'>
      <DialogTitle id='install-pwa-title'>Installer l’application VVMT</DialogTitle>
      <DialogContent id='install-pwa-description' dividers={true}>
        <p>
          Ce site peut être installé comme une application pour en faciliter l’utilisation ! Cliquez sur
          <img src={share} style={{ margin: 'auto 4px 8px' }} alt='Partager' height='20' width='20' />
          en bas de l’écran puis sur “ajouter à l’écran d’accueil”.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} color='primary'>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends Component {
  state = { hasError: false };

  componentDidCatch(error) {
    // Display fallback UI
    this.setState({ hasError: true, error });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info)
    if (!__ENV__.NODE_ENV || __ENV__.NODE_ENV === 'development') {
      console.error(error);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Fragment>
          <h1>Un problème avec l'application, revenez plus tard !</h1>
          {(!__ENV__.NODE_ENV || __ENV__.NODE_ENV === 'development') && (
            <p
              style={{
                background: '#FFF',
                color: 'red',
                padding: 5,
                textAlign: 'center',
              }}>
              {this.state.error.message}
            </p>
          )}
        </Fragment>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]),
};

import { Box, StyleRules, WithStyles, withStyles, withWidth } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import React from 'react';
import MultipleWeeksPicker from '../CustomInput/MultipleWeeksPicker';
import GridContainer from '../Grid/GridContainer';
import ItemGrid from '../Grid/ItemGrid';

const WeekPeriodForm: React.FC<IProps> = ({ type, name, initWeekPeriod, classes, onSelect }: IProps) => {
  return (
    <form className={classes.formContainer}>
      <GridContainer>
        <ItemGrid xs={12} sm={3} className={classes.labelDisapear}>
          <Box>
            {type === 'college' ? 'Collège' : 'Offre'}
          </Box>
        </ItemGrid>
        <ItemGrid xs={12} sm={7}>
          <Box className={classes.name}>
            {name}
          </Box>
        </ItemGrid>

        <ItemGrid xs={12} sm={3}>
          <FormLabel className={classes.labelHorizontal}>
            Période de stage
          </FormLabel>
        </ItemGrid>
        <ItemGrid xs={12} sm={7}>
          {initWeekPeriod && (
            <MultipleWeeksPicker
              key='MultipleWeeksPicker'
              disablePast={true}
              limit={3}
              onSelect={onSelect}
              value={initWeekPeriod}
              isWeekPeriodAsked
            />
          )}
        </ItemGrid>
      </GridContainer>
    </form>
  );
};

const profilPageStyle: StyleRules = {
  formContainer: {
    '@media (max-width: 1000px)': {
      width: '100%',
    },
    width:'900px',
    marginTop: '5vh',
  },
  labelDisapear: {
    '@media (max-width: 600px)': {
      display: 'none',
    },
  },
  name: {
    height: '42px',
    marginBottom: '10px',
  },
  labelHorizontal: {
    width: '100%',
    paddingTop: '15px',
    color: '#000000',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    marginRight: '0',
    '@media (min-width: 992px)': {
      float: 'right',
    },
  },
};

interface IProps extends WithStyles<typeof profilPageStyle> {
  type: 'college' | 'offer';
  name: string;
  initWeekPeriod: any;
  classes: any;
  onSelect: (periods) => void;
}

export default withStyles(profilPageStyle)(WeekPeriodForm);

import Settings from '@material-ui/icons/Settings';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import List from '@material-ui/icons/List';
import SentimentSatisfied from '@material-ui/icons/SentimentSatisfied';
import Assignment from '@material-ui/icons/Assignment';
import Description from '@material-ui/icons/Description';

export const routes = [
  {
    user: true,
    path: '/modifier-profil',
    name: 'Modifier mon profil',
    icon: Settings,
  },
  {
    user: true,
    path: '/deconnexion',
    name: 'Déconnexion',
    icon: PowerSettingsNew,
  },
  {
    path: '/classes',
    name: 'Mes classes',
    icon: List,
  },
  {
    path: '/annonces',
    name: 'Les offres disponibles',
    icon: Description,
  },
  {
    path: '/eleves',
    name: 'Les élèves de mon collège',
    icon: SentimentSatisfied,
  },
  {
    path: '/contact',
    name: 'Je contacte VVMT',
    icon: Assignment,
  },
];

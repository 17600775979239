import React from 'react';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const style = {
  grid: {
    padding: '0 15px !important',
  },
  gridNoPadding: {
    padding: '0 !important',
  },
};

function ItemGrid({ ...props }) {
  const { classes, children, noPadding, className, ...rest } = props;

  return (
    <Grid item {...rest} className={`${classes[noPadding ? 'gridNoPadding' : 'grid']} ${className || ''}`}>
      {children}
    </Grid>
  );
}

ItemGrid.defaultProps = {
  noPadding: false,
};

ItemGrid.propTypes = {
  noPadding: PropTypes.bool,
};

export default withStyles(style)(ItemGrid);

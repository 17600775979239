// ##############################
// // // Header styles
// #############################

import {
  //containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
} from 'assets/styles/jss/main';

const headerStyle = {
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    width: '100%',
    zIndex: '1029',
    position: 'absolute',
    color: '#555555',
    border: '0',
    borderRadius: '3px',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  },
  container: {
    minHeight: '50px',
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    '&:hover,&:focus': {
      background: 'transparent',
    },
  },
  primary: {
    backgroundColor: primaryColor,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor,
    color: '#FFFFFF',
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: '#555555',
  },
  sidebarMinimizeRTL: {
    padding: '0 15px 0 0 !important',
  },
  sidebarMiniIcon: {
    width: '20px',
    height: '17px',
  },
  appResponsive: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  responsiveMenu: {
    width: '100%',
    height: 65,
    background: `${primaryColor} !important`,
    display: 'inline-flex;',
  },
  burgerMenu: {
    color: whiteColor,
    height: 'auto',
    width: '50px',
    marginLeft: '10px',
  },
  cleanHeader: {
    width: 0,
    padding: '0 !important',
  },
};

export default headerStyle;

import { List, Settings, PowerSettingsNew } from '@material-ui/icons';

export const routes = [
  {
    user: true,
    path: '/modifier-profil',
    name: 'Modifier mon profil',
    icon: Settings,
  },
  {
    user: true,
    path: '/deconnexion',
    name: 'Déconnexion',
    icon: PowerSettingsNew,
  },
  {
    path: '/annonces',
    name: 'Les annonces',
    icon: List,
  },
];

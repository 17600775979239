import React from 'react';
import Loadable from 'react-loadable';

import CircularLoader from 'components/Loader/CircularLoader';
import Association from './association';

const Auth = Loadable({
  loader: () => import('./auth'),
  loading: () => <CircularLoader />,
});

const Administrator = Loadable({
  loader: () => import('./administrator'),
  loading: () => <CircularLoader />,
});

const College = Loadable({
  loader: () => import('./college'),
  loading: () => <CircularLoader />,
});

const Guest = Loadable({
  loader: () => import('./guest'),
  loading: () => <CircularLoader />,
});

const HR = Loadable({
  loader: () => import('./hr'),
  loading: () => <CircularLoader />,
});

const InactiveStudent = Loadable({
  loader: () => import('./inactiveStudent'),
  loading: () => <CircularLoader />,
});

const Pro = Loadable({
  loader: () => import('./pro'),
  loading: () => <CircularLoader />,
});

const Student = Loadable({
  loader: () => import('./student'),
  loading: () => <CircularLoader />,
});

const getRoutes = (type) => {
  switch (type) {
    case 'administrator':
      return <Administrator />;
    case 'association':
      return <Association />;
    case 'college':
      return <College />;
    case 'guest':
      return <Guest />;
    case 'hr':
      return <HR />;
    case 'inactiveStudent':
      return <InactiveStudent />;
    case 'pro':
      return <Pro />;
    case 'student':
      return <Student />;
    default:
      return <Auth />;
  }
};

export default getRoutes;

import {
  primaryColor,
  grayColor,
  redColor,
  yellowColor,
  purpleColor,
  pinkColor,
  orangeColor,
  lightGrayColor,
  blackColor,
} from '../../../assets/styles/jss/main';

const chipStyle = {
  root: {},
  sep: {
    marginRight: '.3rem',
    marginBottom: '.3rem',
  },
  bold: {
    fontWeight: 900,
  },
  primaryColor: {
    background: primaryColor,
  },
  secondaryColor: {
    background: grayColor,
  },
  redColor: {
    background: redColor,
  },
  yellowColor: {
    background: yellowColor,
  },
  purpleColor: {
    background: purpleColor,
  },
  pinkColor: {
    background: pinkColor,
  },
  orangeColor: {
    background: orangeColor,
  },
  lightGrayColor: {
    background: lightGrayColor,
    color: blackColor,
  },
  small: {
    fontSize: '.65rem',
    height: 15,
  },
};

export default chipStyle;

import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import SearchIcon from '@material-ui/icons/Search';

export const routes = [
  {
    user: true,
    path: '/deconnexion',
    name: 'Déconnexion',
    icon: PowerSettingsNew,
  },
  {
    path: '/annonces',
    name: 'Trouver un stage',
    icon: SearchIcon,
  },
];

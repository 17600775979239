import React, { Component, Fragment } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import FormLabel from '@material-ui/core/FormLabel';

// @material-ui/icons
import Settings from '@material-ui/icons/Settings';
import AddAlert from '@material-ui/icons/AddAlert';

// core components
import GridContainer from 'components/Grid/GridContainer';
import ItemGrid from 'components/Grid/ItemGrid';
import IconCard from 'components/Cards/IconCard';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import Snackbar from 'components/Snackbar/Snackbar';
import ErrorBoundaries from 'components/Wrapper/ErrorBoundaries';

// others
import config from 'config';
import { Context } from 'context/Context';
import ProfilPageStyle from 'views/Common/style/profilPageStyle';
import FormHOC from '../../hoc/FormHOC';
import { transformPhone, readablePhone } from 'helpers/helpers';
import InfoAsso from 'views/Association/InfoAsso.tsx';
class ProfilPage extends Component {
  componentDidMount() {
    let email, account;
    const { state } = this.props.location;
    const { handleOnChange } = this.props;
    if (state && state.user) {
      email = state.user.email;
      account = state.user.account;
    } else {
      email = this.props.context.state.user.email;
      account = this.props.context.state.user.account;
    }

    handleOnChange(email, 'email', 'email');
    if (account.first_name) {
      handleOnChange(account.first_name, 'firstName', 'length', 3);
    }
    if (account.last_name) {
      handleOnChange(account.last_name, 'lastName', 'length', 3);
    }
    if (account.phone) {
      handleOnChange(readablePhone(account.phone), 'phone', 'optionnalPhone');
    }
  }

  componentWillUnmount() {
    this.props.context.closeSnackbar();
  }

  updateUser = () => {
    const {
      email,
      firstName: first_name,
      lastName: last_name,
      password,
      confirmPassword,
      handleOnSubmit: canSubmit,
    } = this.props;
    const phone = transformPhone(this.props.phone);

    const validations = ['email', 'firstName', 'lastName'];
    if (password || confirmPassword) {
      validations.push('password', 'confirmPassword');
    }
    if (phone) {
      validations.push('phone');
    }
    if (!canSubmit(validations)) {
      return null;
    }

    const { updateUser, handleSnackbar } = this.props.context;
    const { state } = this.props.location;

    const _id = state && state.user ? state.user._id : this.props.context.state.user._id;

    const body = {
      email: email,
      'account.first_name': first_name,
      'account.last_name': last_name,
      ...(password && { password }),
      ...(phone && { 'account.phone': phone }),
    };

    axios
      .put(`${config.API_URL}/api/users/${_id}`, body)
      .then((res) => {
        if (this.props.context.state.user.account.type === 'college') {
          updateUser(res.data.user);
        }
        handleSnackbar({
          status: 'success',
          message: res.data.message,
        });
      })
      .catch((e) => {
        const message =
          e.response && e.response.data.error.code === 11000
            ? 'Un utilisateur avec cet email existe déjà'
            : 'Oups.. Un problème est survenu';

        handleSnackbar({
          status: 'danger',
          message,
        });
      });
  };

  render() {
    const {
      classes,
      email,
      emailState,
      firstName,
      firstNameState,
      lastName,
      lastNameState,
      phone,
      phoneState,
      passwordState,
      confirmPasswordState,
      handleOnChange,
      clearInput,
    } = this.props;

    const {
      closeSnackbar,
      state: { snackbar },
    } = this.props.context;

    const snackbarContainer = snackbar && snackbar.isSnackbar && (
      <Snackbar
        key='snackbar'
        place='tr'
        color={snackbar.status}
        icon={AddAlert}
        message={snackbar.message}
        open={true}
        closeNotification={closeSnackbar}
        close
      />
    );

    const content = (
      <form className={classes.formContainer}>
        <GridContainer>
          <ItemGrid xs={12} sm={3}>
            <FormLabel className={classes.labelHorizontal}>Email</FormLabel>
          </ItemGrid>

          <ItemGrid xs={12} sm={7}>
            <CustomInput
              success={emailState === 'success'}
              error={emailState === 'error'}
              id='email'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'email',
                value: email || '',
                onChange: (event) => handleOnChange(event.target.value, 'email', 'email'),
              }}
              required
            />
          </ItemGrid>

          <ItemGrid xs={12} sm={3}>
            <FormLabel className={classes.labelHorizontal}>Prénom</FormLabel>
          </ItemGrid>

          <ItemGrid xs={12} sm={7}>
            <CustomInput
              success={firstNameState === 'success'}
              error={firstNameState === 'error'}
              id='firstname'
              value={firstName || ''}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => handleOnChange(event.target.value, 'firstName', 'length', 3),
              }}
              required
            />
          </ItemGrid>

          <ItemGrid xs={12} sm={3}>
            <FormLabel className={classes.labelHorizontal}>Nom de famille</FormLabel>
          </ItemGrid>

          <ItemGrid xs={12} sm={7}>
            <CustomInput
              success={lastNameState === 'success'}
              error={lastNameState === 'error'}
              id='lastname'
              value={lastName || ''}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => handleOnChange(event.target.value, 'lastName', 'length', 3),
              }}
              required
            />
          </ItemGrid>

          <ItemGrid xs={12} sm={3}>
            <FormLabel className={classes.labelHorizontal}>Téléphone</FormLabel>
          </ItemGrid>

          <ItemGrid xs={12} sm={7}>
            <CustomInput
              success={phoneState === 'success'}
              error={phoneState === 'error'}
              id='phone'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: phone || '',
                onChange: (event) => handleOnChange(event.target.value, 'phone', 'optionnalLandline'),
              }}
            />
          </ItemGrid>

          <ItemGrid xs={12} sm={3}>
            <FormLabel className={classes.labelHorizontal}>Nouveau mot de passe</FormLabel>
          </ItemGrid>

          <ItemGrid xs={12} sm={7}>
            <CustomInput
              success={passwordState === 'success'}
              error={passwordState === 'error'}
              id='password'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'password',
                onChange: (event) => {
                  const { value } = event.target;
                  if (value) {
                    return handleOnChange(value, 'password', 'password');
                  }
                  return clearInput(['password']);
                },
              }}
            />
          </ItemGrid>
          <ItemGrid xs={12} sm={3}>
            <FormLabel className={classes.labelHorizontal}>Confirmer mot de passe</FormLabel>
          </ItemGrid>

          <ItemGrid xs={12} sm={7}>
            <CustomInput
              success={confirmPasswordState === 'success'}
              error={confirmPasswordState === 'error'}
              helpText='À renseigner uniquement en cas de changement'
              id='confirm-password'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'password',
                onChange: (event) => {
                  const { value } = event.target;
                  if (value) {
                    return handleOnChange(value, 'confirmPassword', 'equalTo', 'password');
                  }
                  return clearInput(['confirmPassword']);
                },
              }}
            />
          </ItemGrid>
        </GridContainer>
      </form>
    );

    const footer = (
      <Fragment>
        {this.props.context.state.user.account.type !== 'college' && (
          <Button
            color='danger'
            onClick={() => {
              const { ...state } = this.props.history.location.state;
              this.props.history.push({
                pathname: '/colleges',
                state,
              });
            }}
            customClass={classes.footerButton}>
            Retour
          </Button>
        )}
        <Button color='primary' onClick={this.updateUser} customClass={classes.footerButton}>
          Valider
        </Button>
      </Fragment>
    );

    return (
      <ErrorBoundaries>
        {snackbarContainer}
        <GridContainer justifyContent='center'>
          <ItemGrid xs={12}>
            <IconCard
              icon={Settings}
              title='Profil utilisateur'
              iconColor='pink'
              footerAlign='center'
              footer={footer}
              content={content}
            />
          </ItemGrid>
        </GridContainer>
        <div style={{ marginTop: '20px' }}>
          <InfoAsso context={this.props.context} location={this.props.location} />
        </div>
      </ErrorBoundaries>
    );
  }
}

ProfilPage.propTypes = {
  context: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const WrappedComponent = FormHOC(ProfilPage);

export default withStyles(ProfilPageStyle)((props) => (
  <Context.Consumer>{(context) => <WrappedComponent {...props} context={context} />}</Context.Consumer>
));
